<template>
  <v-app>
    <v-app-bar
      app
      clipped-right
      color="primary"
      dark
      extension-height="0"
    >
      <v-app-bar-nav-icon
        v-if="mobile && loggedIn"
        @click.stop="showDrawer = !showDrawer"
      />

      <v-toolbar-title>
        <v-btn
          v-if="$route.name !== 'home' && $route.name !== 'login'"
          :aria-label="labels.back"
          icon
          :to="backRoute"
          >
          <v-icon v-text="'$back'" />
        </v-btn>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              :aria-label="labels.back"
              class="px-0"
              text
              tabindex="99"
              :to="backRoute"
            >
              <span v-if="!mobile && title" class="pr-1 section-title">
                {{ title }}
              </span>
              {{ section }}
            </v-btn>
          </template>
          <span>{{ labels.back }}</span>
        </v-tooltip>
      </v-toolbar-title>
      <v-spacer v-if="loggedIn" />

      <v-btn
        v-if="loggedIn && !mobile"
        :aria-label="labels.logout"
        text
        @click.prevent="logOut()"
        data-test="log-in-out-button"
        class="px-0 justify-start"
      >
        <span>{{ labels.logout }}</span>
        <v-icon class="ml-1" v-text="'$logout'" />
      </v-btn>

      <template v-slot:extension>
        <v-progress-linear
          v-if="loading"
          color="secondary"
          indeterminate
        />
      </template>
    </v-app-bar>

    <v-navigation-drawer
      v-if="loggedIn"
      v-model="showDrawer"
      color="secondary"
      app
      bottom
      :expand-on-hover="!mobile"
      dark
      data-test="navigation-drawer"
      :height="mobile ? 'auto' : '100%'"
      :mini-variant.sync="navigationdrawerCollapsed"
      :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
    >
      <v-list dense nav>
        <v-list-item
          v-for="item in navigationItems"
          :key="item.title"
          link
          :to="{ name: item.route }"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.caption }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider inset v-if="mobile || ready" />

        <v-list-item
          v-if="mobile"
          @click.prevent="loggedIn ? logOut() : logIn()"
        >
          <v-list-item-icon>
            <v-icon v-text="loggedIn ? '$logout' : '$login'" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ loggedIn ? labels.logout : labels.login }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-img
          v-if="loggedIn"
          class="text-center pt-2"
          style="width: 100%"
          contain
          src="@/assets/logo.png"
        />
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view/>
    </v-main>

    <v-snackbar
      v-model="showMessage"
      bottom
      :color="messageColor"
      data-test="error-message"
    >
      {{ message }}
      <v-btn
        dark
        text
        @click="showMessage = !showMessage"
      >
        {{ labels.close }}
      </v-btn>
    </v-snackbar>
    <v-footer
      :app="mobile"
      :absolute="mobile"
      padless
      v-if="!loggedIn"
    >
      <v-container fluid class="pa-0">
        <v-row>
        <v-col cols="3" class="pa-0">
          <v-img contain src="@/assets/logo.png" />
        </v-col>
        <v-spacer />
        <v-col cols="9" class="pa-0">
          <v-img contain position="right" src="@/assets/logo_feadr_pndr.png" />
        </v-col>
      </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AuthService from '@/services/auth.service';
import { labels } from './assets/texts.json';

export default {
  name: 'App',
  metaInfo: {
    title: labels.title,
  },
  data: () => ({
    homeRouteName: 'home',
    labels,
    loginRouteName: 'login',
    navigationdrawerCollapsed: null,
    navigationItems: [
      {
        caption: labels.maps,
        icon: '$maps',
        route: 'select',
      },
      {
        caption: labels.stats,
        icon: '$stats',
        route: 'stats',
      },
      {
        caption: labels.settings,
        icon: '$settings',
        route: 'edit',
      },
    ],
    drawer: false,
  }),
  computed: {
    ...mapState('shared', [
      'backRoute',
      'message',
      'messageColor',
      'messageShow',
      'loading',
      'ready',
      'sectionTitle',
    ]),
    ...mapState('auth', ['loggedIn', 'user']),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    section() {
      if (!Array.isArray(this.sectionTitle)) return this.sectionTitle;
      return this.sectionTitle.length > 1
        ? this.sectionTitle[1]
        : this.sectionTitle[0];
    },
    showDrawer: {
      get() {
        return this.mobile ? this.drawer : true;
      },
      set(value) {
        this.drawer = value;
      },
    },
    showMessage: {
      get() {
        return this.messageShow || false;
      },
      set(val) {
        if (!val) {
          this.clearMessage();
        }
      },
    },
    title() {
      return Array.isArray(this.sectionTitle)
        && this.sectionTitle.length > 1
        ? this.sectionTitle[0]
        : null;
    },
  },
  methods: {
    ...mapActions({
      authLogout: 'auth/logout',
      clearMessage: 'shared/clearMessage',
      loginSuccess: 'auth/loginSuccess',
    }),
    logIn() {
      if (this.$route.name !== this.loginRouteName) {
        this.$router.push({ name: this.loginRouteName });
      }
    },
    logOut() {
      AuthService.logout()
        .then(() => {
          console.log('logged out');
        }).catch((error) => {
          console.log(error);
        }).finally(() => {
          this.authLogout();
          this.showDrawer = false;
        });
    },
  },
  watch: {
    loggedIn() {
      if (!this.loggedIn) {
        this.$router.push({ name: this.loginRouteName });
      }
    },
    $route() {
      this.showDrawer = false;
    },
  },
};
</script>

<style lang="scss">
.theme--light {
  &.v-application {
  background: var(--v-background-base);
  }
}
.theme--dark {
  &, &.v-btn:not(.v-btn--text) {
    &.v-btn--active:before {
      opacity: 0;
    }
  }
}
.v-toolbar__extension {
  padding: 0;
}
.v-toolbar__title {
  text-overflow: clip;
}
.section-title::after {
  content: " -";
}
.v-navigation-drawer__content .v-image, footer .v-image {
  max-height: 10vh;
  position: relative;
  margin: 2vh 0;
}
.v-navigation-drawer:not(.v-navigation-drawer--is-mobile)
.v-navigation-drawer__content .v-image {
  bottom: 0;
  left: 0;
  position: absolute;
}
.v-application .v-navigation-drawer.secondary {
  background: linear-gradient(var(--v-secondary-base),
    var(--v-secondary-base) 20%, white 90%) !important;

  &.v-navigation-drawer--is-mobile {
    background: linear-gradient(var(--v-secondary-base),
    var(--v-secondary-base) 60%, white 75%) !important;
  }
}
</style>
