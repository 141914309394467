/* eslint import/no-extraneous-dependencies: ["error", {"devDependencies": true}] */
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/es5/locale/es';
import light from './theme';

import '@/styles/variables.scss';
import '@mdi/font/css/materialdesignicons.css';
import 'typeface-montserrat/index.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    values: {
      account: 'mdi-account',
      accountCheck: 'mdi-account-check',
      addChart: 'mdi-table-large-plus',
      addLayer: 'mdi-layers-plus',
      arrowBottomRightThick: 'mdi-arrow-bottom-right-thick',
      arrowTopRightThick: 'mdi-arrow-top-right-thick',
      back: 'mdi-arrow-left',
      calendar: 'mdi-calendar-range',
      close: 'mdi-close',
      code: 'mdi-barcode',
      delete: 'mdi-delete',
      download: 'mdi-download',
      edit: 'mdi-pencil-outline',
      email: 'mdi-email',
      filter: 'mdi-filter',
      forth: 'mdi-arrow-right',
      gps: 'mdi-crosshairs-gps',
      home: 'mdi-home',
      info: 'mdi-information-outline',
      invisible: 'mdi-eye-off',
      listEdit: 'mdi-playlist-edit',
      lock: 'mdi-lock',
      login: 'mdi-login',
      logout: 'mdi-logout',
      maps: 'mdi-map',
      remove: 'mdi-delete',
      removeLayer: 'mdi-layers-remove',
      save: 'mdi-content-save',
      send: 'mdi-send',
      settings: 'mdi-cog-outline',
      stats: 'mdi-table-large',
      undo: 'mdi-undo',
      username: 'mdi-account',
      visible: 'mdi-eye',
      wait: 'mdi-hand-right',
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: { light },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
});
