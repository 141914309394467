import { setupCache } from 'axios-cache-adapter';
import CacheStore from '@/store/cache.store';
import { download } from '@/helpers';
// import { DEFAULT_SUBPLOTS_PER_PAGE } from '@/store/shared.module';
import HttpService from './http.service';

const store = new CacheStore('statsCache');
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  store,
});

const productTypes = [
  'biofisico',
  'cobertura-suelo',
  'cambio-cobertura-suelo',
  'area-quemada',
];

class StatsService extends HttpService {
  constructor() {
    super(cache);
  }

  cleanup() {
    super.cleanup();
    store.clear();
  }

  getProducts() {
    // devuelve lista de productos para los informes estadísticos
    return this.http
      .get('/stats/products')
      .then((response) => response.data.products)
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }
  
  getYears(plotId) {
    return this.http
      .get(`/stats/years/${plotId}`)
      .then((response) => response.data.years)
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }
  
  /* eslint object-curly-newline: ["error", { "ObjectPattern": "never" }] */
  getValues({ plotId, productType, productIds, years }, format) {
    const params = {
      productIds,
      years,
      ...format && { format },
    };
    return this.http
      .post(
        `/stats/values/${plotId}/${productType}`,
        params,
        {
          responseType: format ? 'blob' : 'json',
        },
      ).then((response) => {
        if (format) return download(response, `${productType}.xlsx`);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  getAnnualProduction(plots, productIds, format) {
    // devuelve los valores de la producción anual
    const params = {
      plots,
      productIds,
      ...format && { format },
    };
    return this.http
      .post(
        '/stats/annual-production',
        params,
        {
          responseType: format ? 'blob' : 'json',
        },
      )
      .then((response) => {
        if (format) return download(response, 'annual-production.xlsx');
        return response.data.values;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  getPlotDaily(plotIds, fromDate, untilDate, productIds, format) {
    // devuelve valores para gráficas de seguimiento anual de parcelas

    const params = {
      plotIds,
      from: fromDate,
      until: untilDate,
      productIds,
      ...format && { format },
    };
    return this.http
      .post(
        '/stats/plot-daily',
        params,
        {
          responseType: format ? 'blob' : 'json',
        },
      )
      .then((response) => {
        if (format) return download(response, 'datos.xlsx');
        return response.data.charts;
      })
      .catch((error) => {
        console.log(error.response);
        throw error;
      });
  }

  getPlotComparison(plotsYears, productIds, format) {
    // devuelve valores para gráficas de seguimiento anual de recintos

    const params = {
      plots: plotsYears,
      productIds,
      ...format && { format },
    };
    return this.http
      .post(
        '/stats/plot-comparison',
        params,
        {
          responseType: format ? 'blob' : 'json',
        },
      )
      .then((response) => {
        if (format) return download(response, 'plot-comparison.xlsx');
        return response.data.charts;
      })
      .catch((error) => {
        console.log(error.response);
        throw error;
      });
  }
  
  /* eslint class-methods-use-this: "off" */
  getProductTypes() {
    return productTypes;
  }
}

export default new StatsService();
