import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import auth from './auth.module';
import shared from './shared.module';

const PERSISTENT_STORAGE_KEY = 'persistentStorage';

Vue.use(Vuex);

export default new Vuex.Store({
  getters: {
    user: (state) => state.auth.user,
  },
  modules: {
    auth,
    shared,
  },
  plugins: [
    createPersistedState({
      paths: ['auth'],
      key: PERSISTENT_STORAGE_KEY,
    }),
  ],
});
