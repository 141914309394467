<template>
  <v-dialog
    v-model="inputVal"
    width="auto"
  >
    <v-card>
      <v-card-title class="headline grey lighten-2">
        {{ labels.digitanimalAliasesEditorTitle }}
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="collars"
          item-key="device_collar"
          :loading="loading"
          :loading-text="labels.loading"
          sort-by='device_collar'
        >
          <template v-slot:item.label="props">
            <v-edit-dialog
              :return-value="props.item.label"
              @open="open(props.item)"
              @save="save(props.item)"
            >
              <v-icon v-text="'$edit'"/> {{ props.item.label }}
              <template v-slot:input>
                <v-text-field
                  v-model="newLabel"
                  :label="labels.edit"
                  single-line
                  ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              @click="deleteItem(item)"
            >
              $delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="inputVal = false"
        >
          {{ labels.close }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint no-param-reassign: ["error", { "props": false }] */
import { labels, errorMessages } from '@/assets/texts.json';
import service from '@/services/digitanimal.service';
import { mapActions } from 'vuex';

export default {
  name: 'DigitanimalAliasesEditor',
  props: [
    'value',
  ],
  data() {
    return {
      labels,
      collars: [],
      headers: [
        {
          text: labels.deviceCollar,
          value: 'device_collar',
        },
        {
          text: labels.label,
          value: 'label',
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
        },
      ],
      newLabel: '',
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      showError: 'shared/errorMsg',
      showMessage: 'shared/displayMsg',
    }),
    loadData() {
      this.loading = true;
      this.collars = [];
      Promise.all([service.getCollarLabels(), service.getInformation()])
        .then((data) => {
          const [collarData, deviceData] = data;

          // Start from current data, add devices not configured
          this.collars = collarData.labels.reduce((acc, curr) => {
            acc[curr.device_collar] = curr;
            return acc;
          }, {});
          if (deviceData.result.data && deviceData.result.data.devices
              && deviceData.result.data.devices.length) {
            deviceData.result.data.devices.forEach((d) => {
              if (!this.collars[d.DEVICE_COLLAR]) {
                this.collars[d.DEVICE_COLLAR] = {
                  device_collar: d.DEVICE_COLLAR,
                  label: '',
                };
              }
            });
          }
          this.collars = Object.values(this.collars);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    open(item) {
      this.newLabel = item.label;
    },
    save(item) {
      const nl = this.newLabel && this.newLabel.trim();
      if (item.label !== nl) {
        this.loading = true;
        service.setCollarLabel(item.device_collar, nl)
          .then(() => {
            item.label = nl;
            this.showMessage(errorMessages.digitanimalCollarUpdated);
            console.log('ok');
          })
          .catch((e) => {
            this.showError(errorMessages.errorUpdatingDigitanimalCollar);
            console.log('err', e);
          }).finally(() => {
            this.loading = false;
          });
      }
    },
    deleteItem(item) {
      this.loading = true;
      service.setCollarLabel(item.device_collar, '')
        .then(() => {
          this.collars.splice(this.collars.indexOf(item), 1);
        })
        .catch((e) => {
          this.showError(errorMessages.errorUpdatingDigitanimalCollar);
          console.log('err', e);
        }).finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    value(v) {
      if (v) {
        this.loadData();
      }
    },
  },
};
</script>

<style scoped>
>>> .v-dialog {
  max-width: 1024px;
}
</style>
