<template>
  <v-container fluid fill-height>
    <v-layout column>
      <v-row
        v-if="!mobile || expandButtons"
        class="headline pb-2 justify-center"
      >
        <span class="secondary--text pr-2">
          {{ labels.currentPlot }}:
        </span>
        <span class="primary--text text-truncate">
          {{ plotLabel }}
        </span>
      </v-row>
      <v-row
        v-if="products"
        no-gutters
        class="d-flex flex-wrap-reverse fill-height"
      >
        <v-col
          cols="12"
          md="3"
          class="d-flex"
        >
          <v-container fluid class="pa-0" id="map-selector">
            <map-buttons
              v-model="wmsLayers"
              :expanded="!mobile || expandButtons"
              :products="products"
              @toggleExpand="toggleExpand"
              @toggleDigitanimal="showDigitanimal = $event"
            />
            <layers-selector
              v-if="wmsLayers && wmsLayers.length"
              v-show="!mobile || !expandButtons"
              :layers="wmsLayers"
              @toggleVisible="toggleLayer"
              @deleteLayer="wmsLayers.splice($event, 1)"
            />
          </v-container>
        </v-col>
        <v-col cols="12" md="9" class="d-flex flex-grow-1 pl-md-3">
          <plot-map
            :plot-id="currentPlot ? currentPlot.id : null"
            :bounds="currentPlot ? currentPlot.bounds : currentPlot"
            :wms-layers="wmsLayers"
            :downloading="downloading"
            :showDigitanimal="showDigitanimal"
          />
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { errorMessages, labels } from '@/assets/texts.json';
import {
  isArrEmpty,
  isObjEmpty,
} from '@/helpers';
import wmsService from '@/services/wms.service';

const LayersSelector = () => import('@/components/LayersSelector.vue');
const MapButtons = () => import('@/components/MapButtons.vue');
const PlotMap = () => import('@/components/PlotMap.vue');

export default {
  name: 'MapsView',
  metaInfo: {
    titleTemplate: `%s | ${labels.maps}`,
  },
  components: {
    LayersSelector,
    MapButtons,
    PlotMap,
  },
  data() {
    return {
      backRoute: {
        name: 'select',
      },
      downloading: false,
      errorMessages,
      expandButtons: false,
      labels,
      products: null,
      yearlyLayers: null,
      wmsLayers: [], // capas seleccionadas
      showDigitanimal: false,
    };
  },
  methods: {
    ...mapActions({
      setBackRoute: 'shared/setBackRoute',
      setSectionTitle: 'shared/setSectionTitle',
      showError: 'shared/errorMsg',
      toggleLoading: 'shared/toggleLoading',
    }),
    toggleExpand(value) {
      this.expandButtons = value;
    },
    toggleLayer(idx) {
      const layer = this.wmsLayers[idx];
      layer.visible = !layer.visible;
      this.$set(this.wmsLayers, idx, layer);
    },
  },
  computed: {
    ...mapState('shared', [
      'currentPlot',
      'loading',
    ]),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    plotLabel() {
      if (isObjEmpty(this.currentPlot)) return null;
      return this.currentPlot.label
        ? `${this.currentPlot.label} (${this.currentPlot['ref-catastral']})`
        : this.currentPlot['ref-catastral'];
    },
    showMap() {
      return !isArrEmpty(this.wmsLayers);
    },
  },
  mounted() {
    this.toggleLoading(true);
    if (!this.currentPlot || isObjEmpty(this.currentPlot)) {
      this.$router.push(this.backRoute);
    } else {
      this.setSectionTitle([
        labels.title,
        labels.maps,
      ]);
      this.setBackRoute(this.backRoute);
      wmsService.getProducts(this.currentPlot.id)
        .then((products) => {
          this.products = [];
          const productSuffixes = [
            ['latest_daily', this.labels.daily, 'daily'],
            ['yearly', this.labels.yearly],
            ['monthly', this.labels.monthly],
            ['weekly', this.labels.weekly],
          ];
          products.forEach((product) => {
            productSuffixes.forEach((suf) => {
              if (product[suf[0]]) {
                this.products.push({
                  text: `${product.name} (${suf[1]})`,
                  product,
                  type: suf[2] || suf[0],
                });
              }
            });
          });
        })
        .catch((error) => {
          console.log(error);
          this.showError(this.errorMessages.unexpected);
        })
        .finally(() => {
          this.toggleLoading(false);
        });
    }
  },
};

</script>

<style lang="scss">
.leaflet-container{
  &,.leaflet-control{
    background: var(--v-background-darken1) !important;
  }
}
.leaflet-control-zoom-in,.leaflet-control-zoom-out{
  background: var(--v-background-lighten1) !important;
}
#map-selector{
  z-index: 10000;
}
</style>
