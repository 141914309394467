<template>
  <div class="download-button" data-app>
    <v-tooltip top attach=".download-button">
      <template v-slot:activator="{ on }">
        <v-btn
          :aria-label="labels.download"
          :loading="loading"
          v-on="on"
          class="my-4"
          color="primary"
          fab
          fixed
          bottom
          @click="$emit('download')"
        >
          <v-icon
            dark
            large
            v-text="'$download'"
          />
        </v-btn>
      </template>
      <span v-if="loading">{{ labels.generatingFile }}</span>
      <span v-else>{{ labels.download }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { labels } from '@/assets/texts.json';

export default {
  name: 'DownloadButton',
  data() {
    return {
      labels,
    };
  },
  computed: {
    ...mapState('shared', [
      'loading',
    ]),
  },
};
</script>

<style>
</style>
