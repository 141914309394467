<template>
  <v-list
    color="background"
    dense
    tile
    class="mx-2 py-1"
  >
    <v-hover
      v-for="(layer, idx) in reversedLayers"
      :key="layer.id"
      v-slot:default="{ hover }"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            :title='layer.name'
          >
            {{ layer.name }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action
          v-if="hover"
          class="ma-0"
        >
          <v-btn
            :title="labels.removeLayer"
            icon
            @click="$emit('deleteLayer', reversedLayers.length - idx - 1)"
          >
            <v-icon v-text="'$remove'" />
          </v-btn>
        </v-list-item-action>
        <v-list-item-action
          v-if="hover"
          class="ma-0"
        >
          <v-btn
            icon
            @click="downloadLayer(layer)"
          >
            <v-icon
              v-if="!layer.downloading && !downloading"
              :title="labels.downloadGeotiff"
              v-text="'$download'"
            />

            <v-icon
              v-if="!layer.downloading && downloading"
              v-text="'$wait'"
            />

            <v-progress-circular
              v-if="layer.downloading"
              indeterminate
              color="secondary"
            />
          </v-btn>
        </v-list-item-action>
        <v-list-item-action class="ma-0">
          <v-btn
            :title="layer.visible ? labels.layerVisible : labels.layerHidden"
            icon
            @click="$emit('toggleVisible', reversedLayers.length - idx - 1)"
          >
            <v-icon v-if="layer.visible" v-text="'$visible'" />
            <v-icon v-if="!layer.visible" v-text="'$invisible'" />
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-hover>
  </v-list>
</template>

<script>
/* eslint no-param-reassign: ["error", { "props": false }] */
import { mapActions, mapState } from 'vuex';
import wmsService from '@/services/wms.service';
import { labels, errorMessages } from '@/assets/texts.json';

export default {
  name: 'LayersSelector',
  props: {
    layers: {
      type: Array[Object],
      default: () => [],
    },
  },
  data() {
    return {
      labels,
      errorMessages,
      downloading: false,
    };
  },
  computed: {
    ...mapState('shared', [
      'currentPlot',
    ]),
    reversedLayers() {
      return this.layers.slice().reverse();
    },
  },
  methods: {
    ...mapActions({
      showError: 'shared/errorMsg',
    }),
    downloadLayer(layer) {
      if (this.downloading || layer.downloading) {
        return;
      }
      this.downloading = true;
      layer.downloading = true;
      let promise;
      if (layer.subplotId) {
        promise = wmsService.downloadYearlyLayer(
          this.currentPlot.id,
          layer.productId,
          layer.subplotId,
        );
      } else {
        promise = wmsService.downloadLayer(
          this.currentPlot.id,
          layer.product_id,
          layer.survey_date,
        );
      }
      promise.then(() => {
        console.log('download ok');
      }).catch((error) => {
        console.log(error);
        this.showError(this.errorMessages.unexpected);
      }).finally(() => {
        layer.downloading = false;
        this.downloading = false;
      });
    },
  },
};
</script>

<style>
</style>
