import { setupCache } from 'axios-cache-adapter';
import CacheStore from '@/store/cache.store';
import axios from 'axios';

const baseURL = process.env.VUE_APP_HTML_BASE_URL;
const store = new CacheStore('htmlCache');
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  store,
});

class HtmlService {
  constructor() {
    this.baseURL = baseURL;
    this.http = axios.create({
      baseURL: this.baseURL,
      ...(cache && { adapter: cache.adapter }),
    });
  }

  getHtml(path) {
    return this.http.get(path, {
      responseType: 'text',
    })
      .then((response) => response.data)
      .catch((error) => {
        console.log(error.response);
        throw error;
      });
  }
}

export default new HtmlService();
