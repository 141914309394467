<template>
  <v-container fill-height fluid class="pa-0">
    <v-col justify="center" cols="12" md="6" offset-md="3">
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >
          <v-toolbar-title>
            {{ label }}
          </v-toolbar-title>
          <v-spacer />

          <v-icon v-text="icon" />

        </v-toolbar>
        <v-card-text>
          <slot />
          <v-text-field
            v-if="ready"
            class="mx-6"
            clearable
            :label="labels.plotSelector"
            :placeholder="labels.search"
            :prepend-icon="'$filter'"
            v-model="plotFilter"
          />
          <v-list
            v-if="ready"
            shaped
            subheader
          >
            <v-list-item
              v-for="plot in pagePlots"
              :key="plot.id"
              link
              @click="selectPlot(plot)"
            >
              <v-list-item-avatar height="100%">
                <v-icon v-text="icon" color="secondary" />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="primary--text">
                  {{ plot.label ? plot.label : plot['ref-catastral'] }}
                </v-list-item-title>
                <v-list-item-subtitle class="secondary--text">
                  {{ plot.label ? plot['ref-catastral'] : '' }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-alert
            v-if="!loading && !ready"
            colored-border prominent
            border="right"
            class="flex-grow-1"
            type="error"
          >
            {{ errorMessages.noPlots }}
          </v-alert>
          <v-pagination
            v-else
            v-show="pageCount > 1"
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
          />
        </v-card-actions>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { errorMessages, labels } from '@/assets/texts.json';
import { isArrEmpty } from '@/helpers';

export default {
  name: 'PlotSelector',
  model: {
    prop: 'filter',
    event: 'setFilter',
  },
  props: {
    // datos de parcelas de usuario
    filter: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '$maps',
    },
    label: {
      type: String,
      default: labels.myPlots,
    },
    plots: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      errorMessages,
      labels,
      plotFilter: null,
    };
  },
  computed: {
    ...mapState('shared', [
      'currentPage',
      'currentPlot',
      'loading',
      'plotsPerPage',
    ]),
    filteredPlots() {
      if (!this.plotFilter) return this.plots;
      const searchStr = this.normalizeStr(this.plotFilter);
      return this.plots.filter((plot) => (
        plot['ref-catastral'].toLowerCase().includes(searchStr)
          || (plot.label
            && this.normalizeStr(plot.label).includes(searchStr))
      ));
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    noCachedPlots() {
      return isArrEmpty(this.filteredPlots);
    },
    page: {
      set(page) {
        this.setPage(page);
      },
      get() {
        return this.currentPage;
      },
    },
    pagePlots() {
      if (!this.noCachedPlots && this.plotNumber > this.responsivePlotsPerPage) {
        return this.filteredPlots.slice(
          this.responsivePlotsPerPage * (this.page - 1),
          this.responsivePlotsPerPage * this.page,
        );
      }
      return this.filteredPlots;
    },
    pageCount() {
      if (this.noCachedPlots) {
        return 0;
      }
      if (this.plotFilter) {
        return Math.ceil(this.filteredPlots.length / this.responsivePlotsPerPage);
      }
      return Math.ceil(this.plotNumber / this.responsivePlotsPerPage);
    },
    plotNumber() {
      return this.plots.length;
    },
    plotSearch: {
      set(value) {
        this.$emit('setFilter', value);
      },
      get() {
        return this.filter;
      },
    },
    ready() {
      return !isArrEmpty(this.plots) || this.plotFilter;
    },
    responsivePlotsPerPage() {
      return this.mobile
        ? Math.floor(this.plotsPerPage / 2)
        : this.plotsPerPage;
    },
    totalVisible() {
      return this.mobile ? 5 : 10;
    },
  },
  methods: {
    ...mapActions('shared', [
      'resetSelector',
      'setPage',
      'setCurrentPlot',
    ]),
    selectPlot(plot) {
      if (plot) {
        this.setCurrentPlot(plot);
      }
    },
    normalizeStr(s) {
      if (!s) {
        return '';
      }
      return s.toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    },
  },
  created() {
    this.resetSelector();
  },
  watch: {
    plotFilter() {
      if (this.noCachedPlots) {
        this.plotSearch = this.plotFilter;
      }
      if (this.plotFilter) {
        this.page = 1;
      }
    },
  },
};
</script>

<style>
.v-list {
  overflow-y: auto;
}
</style>
