<template>
  <v-container>
    <remote-text path="home-text.html"/>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { labels } from '@/assets/texts.json';

const RemoteText = () => import('@/components/RemoteText.vue');

export default {
  name: 'HomeView',
  data() {
    return {
      backRoute: this.$route,
      labels,
    };
  },
  components: {
    RemoteText,
  },
  computed: {
    ...mapState('auth', [
      'loggedIn',
    ]),
  },
  mounted() {
    this.setBackRoute(this.backRoute);
    this.setSectionTitle(labels.title);
    if (!this.loggedIn) {
      this.$router.push({ name: 'login' });
    }
  },
  methods: {
    ...mapActions({
      setBackRoute: 'shared/setBackRoute',
      setSectionTitle: 'shared/setSectionTitle',
    }),
  },
};
</script>

<style>
</style>
