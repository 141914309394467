<template>
  <plot-selector
    v-model="filter"
    :plots="plots"
    :plotNumber="plotNumber"
  />
</template>

<script>
import SelectorMixin from '@/mixins/SelectorMixin';
import { labels } from '@/assets/texts.json';

const PlotSelector = () => import('@/components/PlotSelector.vue');

export default {
  name: 'SelectView',
  mixins: [SelectorMixin],
  components: {
    PlotSelector,
  },
  props: {
    nextView: {
      type: String,
      default: 'maps',
    },
  },
  data() {
    return {
      labels,
    };
  },
  computed: {
    name() {
      return this.labels.selectPlotTitle;
    },
  },
  watch: {
    currentPlot() {
      if (this.currentPlot) {
        this.$router.push(
          { name: this.nextView || 'maps' },
        );
      }
    },
  },
};
</script>

<style />
