import fileDownload from 'js-file-download';
import moment from 'moment';

export const isObjEmpty = (obj) => obj === null || (Object.keys(obj).length === 0
  && obj.constructor === Object);

export const isArrEmpty = (arr) => !arr || !arr.length;

export const mergeArrays = (arr1, arr2) => [...new Set([...arr1, ...arr2])];

export const parseJWT = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('')
    .map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
    .join(''));
  return JSON.parse(jsonPayload);
};

// yyyymm expected as "2020-01" (or any other year-month separator)
export const lastDay = (yyyymm) => new Date(
  parseInt(yyyymm.substr(0, 4), 10),
  parseInt(yyyymm.substr(5, 2), 10),
  0,
).getDate();

export const removeDuplicates = (myArr, prop) => (
  myArr.filter((obj, pos, arr) => (
    arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos
  ))
);

export const uniqueParam = (myArr, prop) => (
  [...new Set(myArr.map((obj) => obj[prop]))]
);

export const download = (response, defaultName) => {
  /* eslint prefer-destructuring: ["error", {array: false}] */
  let filename = null;
  if ('content-disposition' in response.headers) {
    const contdisp = response.headers['content-disposition'];
    const match = contdisp.match(/filename[^;=\n]*=['"](.*?\2|[^;\n]*)['"]/);
    if (match) {
      filename = match[1];
    }
  }
  console.log(response);
  return fileDownload(
    response.data,
    filename || defaultName,
  );
};

export const getClosestDateInArray = (array, dateParam, dateToCompare) => {
  let minDiff = null;
  let mostAccurateDate = array[0];
  array.forEach((item) => {
    const diff = Math.abs(
      moment(dateToCompare).diff(item[dateParam], 'days', true),
    );
    if (minDiff === null || diff < minDiff) {
      minDiff = diff;
      mostAccurateDate = item;
    }
  });
  return mostAccurateDate[dateParam];
};
