<template>
  <v-container
    fluid
  >
    <v-dialog v-if="mobile" v-model="dialog">
      <v-card class="py-2">
        <v-card-text>
          <remote-text path="login-text.html"/>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            {{ labels.close }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row v-else justify="center" >
      <v-col cols="11" lg="10">
        <remote-text path="login-text.html"/>
      </v-col>
    </v-row>

    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="11"
        sm="8"
        md="5"
      >
        <v-form
          @submit.prevent="login"
          v-model="valid"
          ref="form"
          lazy-validation
        >
          <v-card
            class="elevation-12"
            :loading="loading"
          >
            <v-toolbar
              color="primary"
              dark
              flat
            >
              <v-toolbar-title>
                {{ labels.loginMessage }} {{ labels.title}}
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                :label="labels.username"
                :rules="[rules.userRequired, rules.userFormat]"
                :prepend-icon="'$username'"
                tabindex="0"
                type="text"
                v-model="credentials.username"
                data-test="credentials-username"
                @focus="$event.target.select()"
              />

              <v-text-field
                :label="labels.password"
                :rules="[rules.passwordRequired]"
                :append-icon="showPassword ? '$visible' : '$invisible'"
                :prepend-icon="'$lock'"
                :type="showPassword ? 'text' : 'password'"
                v-model="credentials.password"
                @click:append="showPassword = !showPassword"
                data-test="credentials-password"
                @focus="$event.target.select()"
              />
            </v-card-text>
            <v-card-actions class="flex-column d-md-flex flex-md-row-reverse">
              <v-btn
                type="submit"
                class="mb-9 mb-md-0"
                color="primary"
                data-test="login-button"
              >
                {{ labels.login }}
              </v-btn>
              <v-spacer />
              <v-btn
                color="secondary"
                data-text="restore-button"
                text
                x-small
                class="flex-shrink-1"
                @click="showRestoreForm = true"
              >
                {{ labels.lostCredentials }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
    <restore-form v-model="showRestoreForm" />
  </v-container>
</template>

<script>
import authService from '@/services/auth.service';
import { mapActions, mapState } from 'vuex';
import { labels, errorMessages } from '@/assets/texts.json';

const RemoteText = () => import('@/components/RemoteText.vue');
const RestoreForm = () => import('@/components/RestoreForm.vue');

export default {
  name: 'LoginView',
  props: {
    // Nombre de la ruta hacia la que se irá tras hacer login
    homeRouteName: {
      type: String,
      default: 'home',
    },
  },
  components: {
    RemoteText,
    RestoreForm,
  },
  data() {
    return {
      backRoute: {
        name: 'home',
      },
      credentials: {
        username: null,
        password: null,
      },
      demo: {
        username: 'demo',
        password: 'demo1234',
      },
      dialog: true,
      errorMessages,
      labels,
      polling: null,
      showPassword: false,
      showRestoreForm: false,
      rules: {
        passwordRequired: (v) => !!v || labels.enterPassword,
        userRequired: (v) => !!v || labels.enterUsername,
        userFormat: (v) => /^[a-zA-Z0-9]+$/.test(v) || errorMessages.badUsername,
      },
      valid: false,
    };
  },
  computed: {
    ...mapState('shared', [
      'loading',
    ]),
    ...mapState('auth', [
      'loggedIn',
    ]),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapActions({
      loginFailure: 'auth/loginFailure',
      loginSuccess: 'auth/loginSuccess',
      setBackRoute: 'shared/setBackRoute',
      setSectionTitle: 'shared/setSectionTitle',
      setTokenLifetime: 'auth/setTokenLifetime',
      showError: 'shared/errorMsg',
      toggleLoading: 'shared/toggleLoading',
    }),
    login() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.toggleLoading(true);

      authService.login(this.credentials.username, this.credentials.password)
        .then((result) => {
          authService.getTokenLifetime()
            .then((tokenLifetime) => {
              this.setTokenLifetime(
                tokenLifetime,
                authService.startPolling(...tokenLifetime),
              );
            });
          this.loginSuccess(result);
        }).catch((error) => {
          if (!error.response.status === 401) {
            console.log(error);
          }
          this.loginFailure(error);
          this.showError(
            error.response && error.response.status === 401
              ? this.errorMessages.badCredentials
              : this.errorMessages.loginErr,
          );
        }).finally(() => this.toggleLoading(false));
    },
  },
  mounted() {
    this.setSectionTitle(labels.title);
    this.setBackRoute(this.backRoute);
  },
  watch: {
    loggedIn() {
      if (this.loggedIn) {
        this.$router.push(this.backRoute);
      }
    },
  },
};
</script>
