/* eslint class-methods-use-this: ["error", { "exceptMethods": ["cleanup"] }] */
/* eslint-disable no-throw-literal */
/* eslint no-param-reassign: ["error", { "props": false }] */
import axios from 'axios';
import store from '@/store';

const ACCESS_TOKEN = 'token';
const baseURL = process.env.VUE_APP_API_BASE_URL;
const REFRESH_TOKEN = 'refreshToken';
const REFRESH_TOKEN_URL = '/refresh-token';

export const LOGIN_URL = '/login';
export const LOGOUT_URL = '/logout';
export const storedUser = () => store.getters.user;
export const storeToken = (data) => {
  if (data && data.token) {
    store.dispatch('auth/setUser', data);
  } else {
    throw {
      msg: 'Authentication error',
      reason: 'Access token not found',
    };
  }
  return data;
};

class HttpService {
  constructor(cache) {
    this.baseURL = baseURL;
    this.http = axios.create({
      baseURL: this.baseURL,
      ...(cache && { adapter: cache.adapter }),
    });

    this.requestInterceptor = this.setRequestInterceptors();

    this.http.interceptors.response.use(
      // Return a successful response back to the calling service
      (response) => response,
      (error) => {
        if (error.config && (
          error.config.url === REFRESH_TOKEN_URL
          || error.config.url === LOGIN_URL
          || error.config.url === LOGOUT_URL
        )) {
          console.log('Logout, user authentication did not work');
          this.cleanup();
          return new Promise((resolve, reject) => {
            reject(error);
          });
        }
        if (error.response && error.response.status === 401) {
          console.log('Request again with new token');
          return this.refreshTokens()
            .then(() => (
              new Promise((resolve, reject) => {
                this.http.request(error.config).then((response) => {
                  resolve(response);
                }).catch((err) => {
                  reject(err);
                });
              })
            ))
            .catch((err) => {
              console.log(err);
              this.cleanup();
              // return Promise.reject(err);
            });
        }
        // Return any error back to the calling service
        return new Promise((resolve, reject) => {
          reject(error);
        });
      },
    );
  }

  cleanup() {
    store.dispatch('auth/logout');
  }

  async refreshTokens() {
    const ls = storedUser();
    if (ls && REFRESH_TOKEN in ls) {
      const response = await this.http.post(
        REFRESH_TOKEN_URL,
        {
          'refresh-token': ls[REFRESH_TOKEN],
        },
        {
          withCredentials: true,
        },
      );
      console.log('storing refreshed token');
      storeToken(response.data);
      console.log(response.data);
      return response.data;
    }
    return new Promise((resolve, reject) => {
      reject(new Error('Error refreshing tokens'));
    });
  }

  setRequestInterceptors() {
    return this.http.interceptors.request.use(
      (reqConfig) => {
        const ls = storedUser();
        if (ls && ACCESS_TOKEN in ls) {
          reqConfig.headers.authorization = ls[ACCESS_TOKEN];
        }
        return reqConfig;
      },
      (err) => {
        Promise.reject(err);
      },
    );
  }
}

export default HttpService;
