/* eslint no-underscore-dangle: "off" */

import { setupCache } from 'axios-cache-adapter';
import CacheStore from '@/store/cache.store';
import HttpService from './http.service';

const DIGITANIMAL_URL = '/digitanimal';

const store = new CacheStore('digitanimalCache');
const cache = setupCache({
  exclude: { query: false },
  maxAge: 5 * 60 * 1000, // 5 mintues
  store,
});
const cachedConfTimeout = 10 * 60 * 1000;

let cachedConf = false;
let cachedConfTime = 0;

class DigitanimalService extends HttpService {
  constructor() {
    super(cache);
    this._userEnabled = localStorage.getItem('digitanimal.userEnabled') !== false;
  }

  cleanup() {
    cachedConf = false;
    cachedConfTime = 0;
    super.cleanup();
    store.clear();
  }

  getConfiguration() {
    if (Date.now() - cachedConfTime < cachedConfTimeout) {
      return Promise.resolve(cachedConf);
    }
    return this.http
      .get(`${DIGITANIMAL_URL}/getConfiguration`)
      .then((response) => {
        cachedConf = response.data;
        cachedConfTime = Date.now();
        return response.data;
      })
      .catch((error) => {
        console.log(error.response);
        throw error;
      });
  }

  saveConfiguration(username, password) {
    return this.http
      .post(`${DIGITANIMAL_URL}/saveConfiguration`, {
        user: username, password,
      }).then((response) => {
        cachedConf = response.data;
        cachedConfTime = Date.now();
        store.clear();
        return response.data;
      });
  }

  getInformation() {
    return this.http
      .get(`${DIGITANIMAL_URL}/getInformation`)
      .then((response) => response.data);
  }

  get userEnabled() {
    return this._userEnabled;
  }

  set userEnabled(enabled) {
    this._userEnabled = enabled;
    localStorage.setItem('digitanimal.userEnabled', enabled);
  }

  getCollarLabels() {
    return this.http
      .get(`${DIGITANIMAL_URL}/getCollarLabels`)
      .then((response) => response.data);
  }

  setCollarLabel(collar, label = '') {
    return this.http
      .post(`${DIGITANIMAL_URL}/setCollarLabel`, {
        device_collar: collar,
        label,
      })
      .then((resp) => {
        store.clear();
        return resp.data;
      });
  }
}

export default new DigitanimalService();
