<template>
  <l-map
    :bounds="maxBounds"
    :center.sync="mapCenter"
    :crs="crs"
    @ready="saveInitialStatus"
    :zoom.sync="zoom"
    :minZoom="initialZoom"
    :options="{ zoomControl: false, attributionControl: false }"
    ref="leafletMap"
  >

    <l-wms-tile-layer
      version="1.3.0"
      base-url="https://www.ign.es/wms-inspire/pnoa-ma"
      layers="OI.OrthoimageCoverage"
      attribution="IGN"
    />

    <!-- <l-wms-tile-layer
      version="1.3.0"
      base-url="https://wms.mapa.gob.es/sigpac/wms"
      layers="sigpac:recinto"
      attribution="SIGPAC"
      :transparent="true"
      format="image/png"
      :tileSize="tileSize"
      :zIndex="999"
    /> -->
    
    <l-geo-json
      v-if="geojson"
      :geojson="geojson"
      :optionsStyle="geojsonOptions"
    />

    <l-control-scale
      position="bottomleft"
      :imperial="false"
    />

    <l-control-attribution
      position="bottomright"
      :prefix="labels.title"
    />

    <l-control-zoom
      position="bottomright"
    />

    <l-wms-tile-layer
      v-for="(wmsLayer, index) in wmsLayers"
      :key="wmsLayer.id"
      version="1.3.0"
      :base-url="wmsUrl"
      :layers="wmsLayer.wms_layer"
      :visible="wmsLayer.visible"
      :name="wmsLayer.name"
      :format="wmsLayer.format"
      :transparent="wmsLayer.transparent"
      :zIndex="index + 3"
      attribution="COMPLUTIG"
      layer-type="base"
      @ready="toggleLoading(false)"
    />

    <l-marker-cluster>
      <l-marker v-for="dm of digitanimalMarkers"
        :key="dm.DEVICE_COLLAR"
        :visible="showDigitanimal"
        :lat-lng="[ dm.LAT, dm.LNG ]"
        :icon="digitanimalIcon"
      >
        <l-popup class="text-center">
          <div v-if="dm.label" class="font-weight-bold">{{ dm.label }}</div>
          <div class="font-weight-bold">{{ dm.DEVICE_COLLAR }}</div>
          <div>{{ dm.DEVICE_TIME }}</div>
          <div>{{ dm.LAT }},{{ dm.LNG }}</div>
        </l-popup>
      </l-marker>
    </l-marker-cluster>

    <l-control v-if="ready" position="topleft">
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="resetMap" v-on="on">
          <v-icon color="secondary" v-text="'$gps'" />
          </v-btn>
        </template>
        <span>{{ labels.center }}</span>
      </v-tooltip>
    </l-control>

    <l-control
      v-if="ready"
      position="topright"
    >
      <img
        class="legend-graphic"
        :src="topLayerLegendGraphicUrl"
      />
    </l-control>

  </l-map>
</template>

<script>
/* eslint no-underscore-dangle: ["error", { "allow": ["_zoom"] }] */
import { mapActions } from 'vuex';
import L from 'leaflet';
import {
  LMap,
  LControl,
  LControlScale,
  LControlZoom,
  LWMSTileLayer,
  LControlAttribution,
  LMarker,
  LPopup,
  LGeoJson,
} from 'vue2-leaflet';
import Vue2LeafletMarkercluster from 'vue2-leaflet-markercluster/Vue2LeafletMarkercluster.vue';
import digitanimalService from '@/services/digitanimal.service';
import wmsService from '@/services/wms.service';
import { isArrEmpty } from '@/helpers';
import { labels } from '@/assets/texts.json';
import digitanimalIcon from '@/assets/digitanimal-marker.png';

let digitanimalInterval;

export default {
  props: {
    // límites de visualización de la parcela
    bounds: {
      type: Array[Number],
      default: () => [],
    },
    wmsLayers: {
      type: Array[Object],
      default: () => [],
    },
    plotId: {
      type: Number,
      default: null,
    },
    showDigitanimal: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    LMap,
    LControl,
    LControlScale,
    LControlZoom,
    'l-wms-tile-layer': LWMSTileLayer,
    LControlAttribution,
    LMarker,
    LPopup,
    'l-marker-cluster': Vue2LeafletMarkercluster,
    LGeoJson,
  },
  data() {
    return {
      crs: L.CRS.EPSG4326,
      labels,
      mapCenter: this.boundsCenter(this.bounds),
      wmsUrl: wmsService.getServiceUrl(this.plotId),
      maxBounds: this.bounds ? L.latLngBounds([
        [this.bounds[0].lat, this.bounds[0].lng],
        [this.bounds[1].lat, this.bounds[1].lng],
      ]) : null,
      zoom: 0,
      initialStatus: null,
      tileSize: 256,
      digitanimalMarkers: [],
      digitanimalIcon: L.icon({
        iconUrl: digitanimalIcon,
        iconSize: [22, 22],
        iconAnchor: [0, 22],
        popupAnchor: [11, -22],
      }),
      geojson: null,
      geojsonOptions: {
        fillOpacity: 0,
      },
    };
  },
  computed: {
    initialZoom() {
      return this.initialStatus ? Math.min(this.initialStatus._zoom, 18) : 0;
    },
    ready() {
      return !isArrEmpty(this.wmsLayers);
    },
    topLayerLegendGraphicUrl() {
      for (let i = this.wmsLayers.length - 1; i >= 0; i -= 1) {
        if (this.wmsLayers[i].visible) {
          return wmsService.getLegendGraphicUrl(this.wmsLayers[i].wms_layer, 20, 20);
        }
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      toggleLoading: 'shared/toggleLoading',
    }),
    boundsCenter(bounds) {
      if (bounds && !isArrEmpty(bounds)) {
        return [
          bounds.reduce((r, bound) => r + bound.lat, 0) / 2,
          bounds.reduce((r, bound) => r + bound.lng, 0) / 2,
        ];
      }
      return [];
    },
    resetMap() {
      console.log('resetmap!');
      this.zoom = this.initialZoom;
      this.mapCenter = this.boundsCenter(this.bounds);
    },
    saveInitialStatus(status) {
      console.log('save initial status');
      this.initialStatus = { ...status };
    },
    updateDigitanimal() {
      if (this.showDigitanimal) {
        digitanimalService.getInformation()
          .then((data) => {
            if (!data.result.success || !data.result.data || !data.result.data.devices) {
              return;
            }
            this.digitanimalMarkers = data.result.data.devices;
          })
          .catch(() => {
            console.log('Error getting digitanimal data');
          });
      }
    },
  },
  created() {
    digitanimalInterval = setInterval(this.updateDigitanimal, 5 * 60 * 1000);
  },
  destroyed() {
    clearInterval(digitanimalInterval);
  },
  mounted() {
    this.toggleLoading(false);
    const mapElem = this.$refs.leafletMap.$el;
    this.tileSize = Math.max(mapElem.clientHeight, mapElem.clientWidth);
    this.updateDigitanimal();
    wmsService.getGeoJson(this.plotId)
      .then((g) => { this.geojson = g; });
  },
  watch: {
    bounds() {
      if (this.bounds) {
        this.maxBounds = L.latLngBounds([
          [this.bounds[0].lat, this.bounds[0].lng],
          [this.bounds[1].lat, this.bounds[1].lng],
        ]);
      }
    },
    showDigitanimal(v) {
      if (v) {
        this.updateDigitanimal();
      }
    },
  },
};
</script>

<style>
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
.legend-graphic {
  display: block;
}
.leaflet-control-attribution {
  max-width: 50vw;
}
</style>
