<template>
  <v-container
    fluid
    fill-height
  >
    <v-row justify="center">
    <v-col md="auto" class="text-center">
      <h1 class="error--text" data-test="error-message">
        {{ errorMessages.error404 }}
      </h1>
      <div class="mt-9">
        <v-alert
          :value="true"
          type="info"
          color="primary"
          data-test="redirect-message"
        >
          {{ labels.redirect }} {{ seconds }} {{ labels.seconds }}...
          <div class="pt-2">
            <v-btn
              tabindex="0"
              data-test="back-home"
              :aria-label="labels.back"
              color="primary"
              depressed
              :to="{ name: goBackRouteName}"
            >
              <v-icon class="mr-2" v-text="'$home'" />
              {{ labels.back }}
            </v-btn>
          </div>
        </v-alert>
      </div>
    </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { errorMessages, labels } from '../assets/texts.json';


// @group complutig
// Muestra una página 404 personalizada y una cuenta atrás para volver a la
// página inicial
export default {
  name: 'Error404',
  metaInfo() {
    return {
      title: `${errorMessages.notFound} - ${labels.title}`,
    };
  },
  props: {
    // Nombre de la ruta hacia la que se volverá tras hacer click o esperar
    // la cuenta atrás
    goBackRouteName: {
      type: String,
      default: 'home',
    },
    // Duración en segundos de la cuenta atrás
    timeout: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      errorMessages,
      labels,
      seconds: this.timeout,
    };
  },
  created() {
    this.countDown();
  },
  methods: {
    countDown() {
      this.seconds -= 1;
      if (this.seconds <= 0) {
        this.$router.push({ name: this.goBackRouteName });
      } else {
        setTimeout(() => this.countDown(), 1000);
      }
    },
  },
};
</script>
