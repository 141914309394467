const defaultState = {
  loggedIn: false,
  polling: null,
  tokenLifetime: null,
  user: null,
};

const auth = {
  namespaced: true,
  state: defaultState,
  actions: {
    loginSuccess({ commit }, user) {
      commit('loginSuccess', user);
    },
    loginFailure({ commit }) {
      commit('loginFailure');
    },
    logout({ commit }) {
      commit('logout');
      // cleanup shared store
      commit('shared/cleanup', null, { root: true });
    },
    setTokenLifetime({ commit }, tokenLifetime, polling) {
      commit('setTokenLifetime', { tokenLifetime, polling });
    },
    setUser({ commit }, user) {
      commit('setUser', user);
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.loggedIn = false;
      state.polling = null;
      state.tokenLifetime = null;
      state.user = null;
    },
    logout(state) {
      state.loggedIn = false;
      state.polling = clearInterval(state.polling);
      state.tokenLifetime = null;
      state.user = null;
    },
    setTokenLifetime(state, { tokenLifetime, polling }) {
      state.polling = polling;
      state.tokenLifetime = tokenLifetime;
    },
    setUser(state, user) {
      state.user = user;
    },
  },
};

export default auth;
