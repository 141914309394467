<template>
  <v-menu
    :disabled="disabled"
    v-model="calendar"
    :close-on-content-click="false"
    transition="scale-transition"
    min-width="0"
    offset-y
    z-index="10000"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="dateFormatted"
        :label="labels.date"
        :hint="labels.dateHint"
        :disabled="disabled"
        outlined
        persistent-hint
        :prepend-icon="'$calendar'"
        readonly
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="currentDate"
      :allowed-dates="allowedDates"
      color="primary"
      first-day-of-week="1"
      :locale="$vuetify.lang.current"
      :max="maxDate"
      :picker-date="pickerDate"
      no-title
      scrollable
      show-current
      show-week
      :range="range"
      @change="change"
      @update:picker-date="updatePickerDate($event)"
    />
  </v-menu>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { labels } from '@/assets/texts.json';


export default {
  name: 'Calendar',
  model: {
    prop: 'date',
    event: 'setDate',
  },
  props: {
    allowedDates: {
      type: Function,
      default: () => true,
    },
    date: {
      type: [String, Array],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    range: {
      type: Boolean,
      default: false,
    },
  },
  inheritAttrs: false,
  data() {
    return {
      calendar: false,
      currentDate: this.date,
      labels,
      maxDate: this.today(),
    };
  },
  computed: {
    ...mapState('shared', [
      'pickerDate',
    ]),
    dateFormatted() {
      return this.formatDate(this.currentDate);
    },
  },
  methods: {
    ...mapActions({
      updatePickerDate: 'shared/setPickerDate',
    }),
    change(date) {
      this.$emit('setDate', date);
      this.calendar = false;
    },
    formatDate(date) {
      const formatted = (this.range ? date : [date]).map(
        (partial) => {
          if (!partial) return null;
          const [year, month, day] = partial.split('-');
          return `${day}/${month}/${year}`;
        },
      );
      return this.range ? formatted.join(' - ') : formatted[0];
    },
    today() {
      return new Date().toISOString().substr(0, 10);
    },
  },
  watch: {
    date() {
      if (!this.range) {
        this.currentDate = this.date;
      }
    },
  },
};
</script>
