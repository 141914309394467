<template>
  <v-row justify="center">
    <v-data-table
      v-if="chartData.values"
      class="elevation-15"
      :headers="tableHeaders"
      :items="tableData"
      multi-sort
      sort-by="year"
    >
      <template v-slot:item="{ item, headers }">
        <tr>
          <td v-for="header in headers" :key="header.value">
            <span v-if="item[header.value + '.attr']"
                  :class="{ 'red--text': item[header.value + '.attr'].includes('bad'),
                    'green--text': item[header.value + '.attr'].includes('good') } ">
              {{ item[header.value] || labels.noData }}
              <v-icon v-if="item[header.value + '.attr'].includes('decreasing')"
                      v-text="'$arrowBottomRightThick'"
                      :title="labels.decreasing"
                      :class="{ 'red--text': item[header.value + '.attr'].includes('bad'),
                        'green--text': item[header.value + '.attr'].includes('good') }"/>
              <v-icon v-if="item[header.value + '.attr'].includes('increasing')"
                      v-text="'$arrowTopRightThick'"
                      :title="labels.increasing"
                      :class="{ 'red--text': item[header.value + '.attr'].includes('bad'),
                        'green--text': item[header.value + '.attr'].includes('good') }" />
            </span>
            <span v-else>
              {{ item[header.value] || labels.noData }}
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-container
      class="chart-container"
      v-if="chartData.charts"
    >
      <div
        v-for="chart in chartData.charts"
        :key="chart.product.id"
      >
        <trend-chart
          v-if="chart.type === 'daily'"
          class="trend-chart"
          :chart="chart"
        />
        
        <monthly-chart
          v-if="chart.type === 'monthly'"
          class="monthly-chart"
          :chart="chart"
        />
      </div>
    </v-container>

  </v-row>
</template>

<script>
/* eslint no-param-reassign: ["error", { "props": false }] */
import { labels } from '@/assets/texts.json';

const TrendChart = () => import('@/components/TrendChart.vue');
const MonthlyChart = () => import('@/components/MonthlyChart.vue');

export default {
  name: 'StatsResults',
  components: {
    TrendChart,
    MonthlyChart,
  },
  props: {
    // datos estadísticos a representar
    chartData: {
      type: Array[Object],
      default: () => [],
    },
    // tipo de informe a representar
    chartSettings: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      labels,
    };
  },
  computed: {
    tableHeaders() {
      if (this.chartData.values) {
        const sortedProds = [...this.chartSettings.products];
        sortedProds.sort((a, b) => a.weight - b.weight || a.name.localeCompare(b.name));
        return [
          { text: labels.year, value: 'year' },
          ...sortedProds.filter((product) => product.yearly).map((product) => ({
            text: product.units ? `${product.name} (${product.units})` : product.name,
            value: `product${product.id}`,
          })),
        ];
      }
      return [];
    },
    tableData() {
      if (this.chartData.values) {
        return this.chartData.values.map((row) => {
          const nr = {
            year: this.chartSettings.productType === 'cambio-cobertura-suelo'
              ? `${row.year - 1} - ${row.year}`
              : row.year,
          };
          row.values.forEach((v) => {
            nr[`product${v.productId}`] = v.value;
            nr[`product${v.productId}.acc`] = v.acc_value;
            nr[`product${v.productId}.attr`] = v.attributes;
          });
          return nr;
        });
      }
      return [];
    },
  },
  methods: {
    getChartHeight(chart) {
      return 380 + chart.series.length * 5;
    },
  },
};
</script>

<style lang="scss">
.v-data-table-header {
  background-color: var(--v-primary-darken2);
  & > tr th, .v-data-table-header__icon, .v-data-table-header__sort-badge {
    color: white !important;
  }
}
.chart-container {
  width: 100%;
}
.trend-chart {
  width: 100%;
  height: 400px;
}
</style>
