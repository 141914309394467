<template>
  <v-container fluid>
    <v-col justify="center" cols="12" md="6" offset-md="3">
      <v-card elevation="15" max-width="95vw" >
        <v-toolbar color="primary" dark>
          <v-toolbar-title class="text-truncate">
            {{ labels.digitanimalSettings }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>

          <v-text-field
            v-model="user"
            color="secondary"
            outlined
            clearable
            hide-details="auto"
            :prepend-inner-icon="'$account'"
            :label="labels.username"
            class="pb-2"
            @input="formTouched(true)"
            :disabled="loading"
          />
          <v-text-field
            v-model="password"
            type="password"
            auto-grow
            clearable
            color="secondary"
            hide-details="auto"
            :label="labels.password"
            outlined
            :prepend-inner-icon="'$lock'"
            @input="formTouched(false)"
            :disabled="loading"
          />

          <div style="min-height: 5px">
            <v-progress-linear
              v-if="loading"
              indeterminate
            />
          </div>
        </v-card-text>
        <v-card-actions class="mt-4 justify-space-around">
          <v-btn
            :disabled="!canUndo || loading"
            color="secondary"
            @click="resetForm"
          >
            <v-icon class="mr-2" v-text="'$undo'" />
            {{ labels.undoChanges }}
          </v-btn>
          <v-btn
            :disabled="!canUndo || loading"
            color="primary"
            @click="saveConfiguration"
          >
            <v-icon class="mr-2" v-text="'$save'" />
            {{ labels.save }}
          </v-btn>
          <v-btn
            :disabled="!canTest || loading"
            color="accent"
            @click="testSettings"
          >
            <v-icon class="mr-2" v-text="'$accountCheck'" />
            {{ labels.testConnection }}
          </v-btn>
          <v-btn
            :disabled="!canTest || loading"
            color="accent"
            @click="showDigitanimalAliasesEditor = true"
          >
            <v-icon class="mr-2" v-text="'$listEdit'" />
            {{ labels.editDigitanimalAliases }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-dialog
      v-model="showSettingsWarning"
      width="500"
      class="text-center"
      >
      <v-card>
        <v-card-text class="pt-4">{{ labels.digitanimalSettingsWarning }}</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="showSettingsWarning = false"
            >
            {{ labels.understood }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <digitanimal-aliases-editor
      v-model="showDigitanimalAliasesEditor"
    />
  </v-container>
</template>

<script>
import { labels } from '@/assets/texts.json';
import { mapActions } from 'vuex';
import service from '@/services/digitanimal.service';

const DigitanimalAliasesEditor = () => import('@/components/DigitanimalAliasesEditor.vue');

const NO_CHANGE_PASSWORD = '****************';

export default {
  name: 'DigitanimalSettings',
  components: {
    DigitanimalAliasesEditor,
  },
  data() {
    return {
      user: '',
      password: '',
      labels,
      loading: true,
      canTest: false,
      canUndo: false,
      remoteConf: null,
      showSettingsWarning: false,
      showDigitanimalAliasesEditor: false,
    };
  },
  mounted() {
    service.getConfiguration()
      .then((conf) => {
        this.remoteConf = conf.digitanimal;
        this.resetForm();
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions({
      showError: 'shared/errorMsg',
      showMessage: 'shared/displayMsg',
      toggleLoading: 'shared/toggleLoading',
    }),
    formTouched(clearPassword = true) {
      this.canTest = false;
      this.canUndo = true;
      if (clearPassword) {
        this.password = '';
      }
    },
    resetForm() {
      if (this.remoteConf) {
        this.user = this.remoteConf.user;
        this.password = this.remoteConf.hasPassword ? NO_CHANGE_PASSWORD : '';
        this.canTest = this.remoteConf.user && this.remoteConf.hasPassword;
      } else {
        this.user = '';
        this.password = '';
        this.canTest = false;
      }
      this.canUndo = false;
    },
    saveConfiguration() {
      this.loading = true;
      service.saveConfiguration(this.user, this.password)
        .then((conf) => {
          this.showMessage(this.labels.settingsUpdated);
          this.remoteConf = conf.digitanimal;
          this.resetForm();
        }).catch(() => {
          this.showError(this.labels.errorUpdatingSettings);
        }).finally(() => {
          this.loading = false;
        });
    },
    testSettings() {
      this.loading = true;
      service.getInformation()
        .then((data) => {
          if (data.result.success) {
            if (data.result.data.devices && data.result.data.devices.length) {
              this.showMessage(this.labels.digitanimalSettingsOk.replace('%DEVICES%', data.result.data.devices.length));
            } else {
              this.showSettingsWarning = true;
            }
          } else {
            console.log(data.result.success);
          }
        })
        .catch(() => {
          this.showError(this.labels.settingsError);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>
