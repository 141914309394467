<template>
  <div>
    <div v-if="loading" class="text-center">
      <v-progress-circular
          :indeterminate="true"
          :size="64"
          :width="6"
          color="primary"
        ></v-progress-circular>
    </div>
    <component v-if="content" v-bind:is="transformed"></component>
    <v-alert v-if="error" dense outlined type="error">
      {{ errorText }}
    </v-alert>
  </div>
</template>
<script>
import { errorMessages } from '@/assets/texts.json';
import {
  VBtn, VIcon, VRow, VCol, VContainer,
} from 'vuetify/lib';
import htmlService from '../services/html.service';

export default {
  props: {
    path: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      content: null,
      error: false,
      errorText: errorMessages.errorGettingText,
    };
  },
  computed: {
    transformed() {
      return {
        template: `<div>${this.content}</div>`,
        components: {
          VBtn,
          VIcon,
          VRow,
          VCol,
          VContainer,
        },
      };
    },
  },
  mounted() {
    this.loading = true;
    htmlService.getHtml(this.path)
      .then((content) => { this.content = content; })
      .catch(() => { this.error = true; })
      .finally(() => { this.loading = false; });
  },
};
</script>
