<template>
  <v-container>
    <v-row v-if="currentPlot">
      <v-col>
        <h1>
          Parcela:
          {{ currentPlot.label || currentPlot['ref-catastral'] }}
          <span class="text-body-1" v-if="currentPlot.label">
            {{ currentPlot['ref-catastral'] }}
          </span>
        </h1>
      </v-col>
    </v-row>
    
    <stats-buttons
      @productTypeChange="productTypeChange"
      @setCharts="setCharts"
      @error="showError"
    />

    <stats-results
      v-if="ready && chartSettings && chartData"
      :chart-settings="chartSettings"
      :chart-data="chartData"
      class="pt-5"
    />

    <download-button
      v-if="ready"
      @download="getData('xlsx')"
    />
  </v-container>
</template>

<script>
/* eslint no-param-reassign: ["error", { "props": false }] */
import { mapActions, mapState } from 'vuex';
import { errorMessages, labels } from '@/assets/texts.json';
import { isArrEmpty, isObjEmpty } from '@/helpers';
import statsService from '@/services/stats.service';

const DownloadButton = () => import('@/components/DownloadButton.vue');
const StatsButtons = () => import('@/components/StatsButtons.vue');
const StatsResults = () => import('@/components/StatsResults.vue');

export default {
  name: 'StatsView',
  metaInfo: {
    titleTemplate: `%s | ${labels.stats}`,
  },
  components: {
    DownloadButton,
    StatsButtons,
    StatsResults,
  },
  data() {
    return {
      backRoute: {
        name: 'stats',
      },
      chartData: null,
      chartSettings: null,
      errorMessages,
      labels,
      products: null,
    };
  },
  computed: {
    ...mapState('shared', [
      'currentPlot',
      'download',
      'loading',
      'ready',
    ]),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {
    if (!this.currentPlot || isObjEmpty(this.currentPlot)) {
      this.$router.push(this.backRoute);
    }
    this.setSectionTitle([
      labels.title,
      labels.stats,
    ]);
    this.setBackRoute(this.backRoute);
  },
  methods: {
    ...mapActions({
      setBackRoute: 'shared/setBackRoute',
      setSectionTitle: 'shared/setSectionTitle',
      showError: 'shared/errorMsg',
      toggleLoading: 'shared/toggleLoading',
      toggleReady: 'shared/toggleReady',
    }),
    productTypeChange(productType) {
      this.chartData = null;
      this.chartType = productType;
    },
    async setCharts(chartSettings) {
      this.chartSettings = chartSettings;
      try {
        this.chartData = await this.getData();
        console.log(this.chartData);
        this.toggleReady(!isArrEmpty(this.chartData.charts) || !isArrEmpty(this.chartData.values));
        if (!this.ready) {
          this.showError(this.errorMessages.noData);
        }
      } catch (error) {
        console.log(error);
        this.showError(this.errorMessages.chartError);
        this.chartData = null;
      }
    },
    getData(format = null) {
      this.toggleLoading(true);
      return statsService.getValues(
        {
          plotId: this.currentPlot.id,
          productType: this.chartSettings.productType,
          productIds: this.chartSettings.products.map((p) => p.id),
          years: this.chartSettings.years,
        }, format,
      )
        .finally(() => {
          this.toggleLoading(false);
        });
    },
  },
};
</script>

<style>
</style>
