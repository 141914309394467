<template>
  <v-autocomplete
    :items="items"
    :label="label"
    :loading="loading"
    :value="value"
    @input="update"
    return-object
    >
  </v-autocomplete>
</template>

<script>
import { labels } from '@/assets/texts.json';

export default {
  name: 'WeekPicker',
  props: {
    loading: Boolean,
    label: String,
    items: Array,
    value: Object,
  },
  inheritAttrs: true,
  data() {
    return {
      labels,
    };
  },
  methods: {
    update(v) {
      this.$emit('input', v);
    },
  },
};
</script>
