import store from '@/store';
import { parseJWT } from '@/helpers';
import StatsService from '@/services/stats.service';
import WmsService from '@/services/wms.service';
import DigitanimalService from '@/services/digitanimal.service';
import HttpService, {
  LOGIN_URL,
  LOGOUT_URL,
  storedUser,
  storeToken,
} from './http.service';

const DELTA = 60; // grace period for access token lifetime
const NBF_DELTA = 0.5; // tolerance fr NBF token parameter
const RATIO = 0.9; // ratio applied to token lifetime before refreshing
const RESET_REQUEST_URL = '/reset-password-request';
const RESET_CONFIRM_URL = '/reset-password-confirm';

class AuthService extends HttpService {
  login(username, password) {
    this.clearCaches();
    return this.http
      .post(LOGIN_URL, {
        username,
        password,
      }, {
        withCredentials: true,
      })
      .then((response) => storeToken(response.data));
  }

  getInitialState() {
    return this.getTokenLifetime()
      .then(([remainingTime, tokenLifetime]) => {
        console.log('Getting initial state', remainingTime, tokenLifetime);
        if (remainingTime) {
          store.dispatch('auth/loginSuccess', storedUser());
          store.dispatch(
            'auth/setTokenLifetime',
            tokenLifetime,
            this.startPolling(remainingTime, tokenLifetime),
          );
        } else {
          this.clearCaches();
        }
      });
  }

  getTokenLifetime = () => {
    console.log('get token lifetime');
    const user = storedUser();
    if (!user || !user.token) {
      console.log('not logged in');
      return Promise.resolve([null, null]);
    }

    let decodedToken = parseJWT(user.token);

    if (decodedToken.nbf > Date.now() / 1000 + NBF_DELTA) {
      // access token not valid yet... login again?
      console.log('token not yet valid');
      return Promise.resolve([null, null]);
    }

    if (decodedToken.exp > Date.now() / 1000 + DELTA) {
      console.log('logged in');
      return Promise.resolve([
        decodedToken.exp - Date.now() / 1000,
        decodedToken.exp - decodedToken.nbf,
      ]);
    }

    console.log('access token timed out');

    return this.refreshTokens()
      .then((tokens) => {
        decodedToken = parseJWT(tokens.token);
        console.log('tokens refreshed');
        console.log(decodedToken);
        return [
          decodedToken.exp - Date.now() / 1000,
          decodedToken.exp - decodedToken.nbf,
        ];
      });
  }

  logout() {
    return this.http.post(LOGOUT_URL)
      .then((response) => response.data)
      .finally(() => {
        // cleanup localStorage and (axios) caches
        this.clearCaches();
      });
  }

  restoreRequest(email) {
    return this.http
      .post(RESET_REQUEST_URL, {
        email,
      });
  }

  restoreConfirm(email, code, password) {
    return this.http
      .post(RESET_CONFIRM_URL, {
        email,
        code,
        password,
      });
  }

  startPolling = (remainingTime, lifetime) => {
    console.log('startPolling', remainingTime, lifetime);
    const pollingInterval = lifetime * RATIO;
    const firstInterval = remainingTime * RATIO;
    if (pollingInterval) {
      console.log(`Refreshing token in ${firstInterval} seconds and then every ${pollingInterval} seconds`);
      return setTimeout(() => setInterval(() => {
        console.log('refreshing tokens');
        this.refreshTokens();
      }, pollingInterval * 1000), firstInterval);
    }
    return null;
  }

  clearCaches() {
    this.cleanup();
    StatsService.cleanup();
    WmsService.cleanup();
    DigitanimalService.cleanup();
  }
}

export default new AuthService();
