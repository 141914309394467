import colors from 'vuetify/lib/util/colors';

export default {
  primary: (
    process.env.NODE_ENV === 'production'
      ? '#b18b68' // color siega
      : colors.lightBlue
  ),
  secondary: (
    process.env.NODE_ENV === 'production'
      ? colors.grey.darken3 // #424242 en lugar de #333
      : colors.blueGrey.darken3
  ),
  background: (
    process.env.NODE_ENV === 'production'
      ? colors.grey.lighten4 // #f5f5f5 en lugar de #f4f4f4
      : colors.grey.lighten4
  ),
};
