import { setupCache } from 'axios-cache-adapter';
import CacheStore from '@/store/cache.store';
import { download } from '@/helpers';
import { DEFAULT_PLOTS_PER_PAGE } from '@/store/shared.module';
import HttpService from './http.service';

const WMS_URL = '/wms';

const store = new CacheStore('wmsCache');
const cache = setupCache({
  exclude: { query: false },
  maxAge: 15 * 60 * 1000, // 15 minutes
  store,
});

class WmsService extends HttpService {
  constructor() {
    super(cache);
  }

  cleanup() {
    super.cleanup();
    store.clear();
  }

  getServiceUrl(plotId = null) {
    if (plotId) {
      return `${this.baseURL}${WMS_URL}?plotId=${plotId}`;
    }
    return `${this.baseURL}${WMS_URL}`;
  }

  getPlots(page, plotsPerPage, filter, withStatYears) {
    console.log('getting plots');
    // devuelve parcelas para el usuario identificado por token
    const params = {
      ...page && {
        offset: (page - 1) * (plotsPerPage || DEFAULT_PLOTS_PER_PAGE),
      },
      ...plotsPerPage && { limit: plotsPerPage },
      ...filter && { filter },
      ...withStatYears && { statYears: true },
    };
    return this.http
      .get('/plots', { params })
      .then((response) => response.data)
      .catch((error) => {
        console.log(error.response);
        throw error;
      });
  }
  
  getGeoJson(plotId) {
    return this.http
      .get(`/plots/${plotId}/geojson`)
      .then((response) => response.data.geojson)
      .catch((error) => {
        console.log(error.response);
        throw error;
      });
  }

  getProducts(plotId) {
    // devuelve lista de productos para una determinada parcela
    return this.http
      .get(`/plots/${plotId}/products`)
      .then((response) => response.data.products)
      .catch((error) => {
        console.log(error.response);
        throw error;
      });
  }

  getLayers(plotId, productId, fromDate, untilDate) {
    // devuelve lista de capas para un determinado producto y rango de fechas

    const params = {
      ...fromDate && { from: fromDate },
      ...untilDate && { until: untilDate },
    };
    return this.http
      .get(
        `/plots/${plotId}/products/${productId}/layers`,
        { params },
      )
      .then((response) => response.data)
      .catch((error) => {
        console.log(error.response);
        throw error;
      });
  }

  getNonDailyLayers(plotId, productId, type) {
    // devuelve lista de capas no diarias para un determinado producto y un tipo
    // (weekly, monthly, yearly)
    return this.http
      .get(
        `/plots/${plotId}/products/${productId}/layers/${type}`,
      )
      .then((response) => response.data.layers)
      .catch((error) => {
        console.log(error.response);
        throw error;
      });
  }

  getRelativeLayers(plotId, productId, relative, year, month) {
    // devuelve lista de capas disponibles antes/después de un determinado mes+año

    return this.http
      .get(
        `/plots/${plotId}/products/${productId}/layers/${relative}/${year}-${month}`,
      )
      .then((response) => response.data)
      .catch((error) => {
        console.log(error.response);
        throw error;
      });
  }

  updatePlot(plotId, label, notes) {
    console.log(`wms-service: label: ${label}, notes: ${notes}`);
    return this.http
      .post(
        `/plots/${plotId}/update`, {
          label,
          notes,
        },
      ).then((response) => {
        console.log(response.result);
        return response;
      });
  }

  downloadLayer(plotId, productId, date) {
    console.log(`Downloading layer for plot ${plotId}, product ${productId} @${date}`);
    return this.http
      .get(
        `/plots/${plotId}/products/${productId}/layers/${date}.tiff`,
        {
          responseType: 'blob',
        },
      )
      .then((response) => download(response, `layer-${date}.tiff`))
      .catch((error) => {
        console.log(error.msg);
        throw error;
      });
  }

  downloadYearlyLayer(plotId, productId, subplotId) {
    console.log(`Downloading yearly layers for plot ${plotId}, product ${productId} and subplot ${subplotId}`);
    return this.http
      .get(
        `/plots/${plotId}/yearly-layers/${productId}/${subplotId}.tiff`,
        {
          responseType: 'blob',
        },
      )
      .then((response) => download(response, `layer-${plotId}_${productId}_${subplotId}.tiff`))
      .catch((error) => {
        console.log(error.msg);
        throw error;
      });
  }

  getLegendGraphicUrl(layerName, width = 20, height = 20) {
    const fixedParams = 'REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png';
    return `${this.getServiceUrl()}?${fixedParams}&WIDTH=${width}&HEIGHT=${height}&LAYER=${encodeURIComponent(layerName)}`;
  }
}

export default new WmsService();
