import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';


Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/home.vue'),
      meta: {
        unauthenticated: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login.vue'),
      meta: {
        unauthenticated: true,
      },
    },
    {
      path: '/select',
      name: 'select',
      component: () => import(
        /* webpackChunkName: "select" */
        '@/views/select.vue'
      ),
    },
    {
      path: '/maps',
      name: 'maps',
      component: () => import(
        /* webpackChunkName: "maps" */
        '@/views/maps.vue'
      ),
    },
    {
      path: '/stats',
      name: 'stats',
      component: () => import(
        /* webpackChunkName: "select" */
        '@/views/select.vue'
      ),
      props: {
        nextView: 'stats-view',
      },
    },
    {
      path: '/stats-view',
      name: 'stats-view',
      component: () => import(
        /* webpackChunkName: "stats" */
        '@/views/stats.vue'
      ),
    },
    {
      path: '/edit',
      name: 'edit',
      component: () => import(
        /* webpackChunkName: "edit" */
        '@/views/edit.vue'
      ),
    },
    {
      path: '/404',
      name: 'notFound',
      component: () => import('@/views/fallback.vue'),
    },
    {
      path: '*',
      redirect: '/404',
    },
  ],
});


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.unauthenticated)) {
    next();
  } else if (store.state.auth.loggedIn || false) {
    next();
  } else {
    next({ name: 'home' });
  }
});

export default router;
