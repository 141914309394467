<template>
  <v-container class="py-0">
    <v-autocomplete
      :items="productItems"
      v-model="currentProduct"
      :label="labels.product"
      return-object
      >
      <template v-slot:item="{ item, parent }">
        <v-list-item-content :class="{'has-parent': item.product.category}">
          <v-list-item-title>
            <v-tooltip
              bottom
              color="primary"
              :max-width="maxWidth"
              offset-overflow
              z-index="12345678"
              >
              <template v-slot:activator="{ on }">
                <span
                  v-html="parent.genFilteredText(item.text)"
                />
                <div class="float-right ml-2" v-if="item.product.notes">
                  <v-icon v-on="on" color="primary" v-text="'$info'" />
                </div>
              </template>
              <span>{{ item.product.notes }}</span>
            </v-tooltip>
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <div v-if="currentProduct">
      <v-autocomplete
        v-if="currentProduct.type === 'yearly' || currentProduct.type === 'monthly'"
        :items="currentProduct.layers || []"
        v-model="period"
        :label="labels.period"
        :loading="loadingProductValues"
        return-object
        >
      </v-autocomplete>
      <week-picker v-if="currentProduct.type === 'weekly'"
        :items="currentProduct.layers || []"
        v-model="period"
        :label="labels.period"
        :loading="loadingProductValues"
        >
      </week-picker>
      <div v-if="currentProduct.type === 'daily'">
        <v-container v-show="expanded" align="stretch" justify="center">
          <v-row>
            <calendar
              v-model="date"
              :allowed-dates="allowedDates"
              :disabled="!currentProduct"
              :key="datePickerReference"
            />
          </v-row>
            <v-row class="flex-nowrap" v-if="currentProduct">
              <v-btn
                class="pa-0"
                color="secondary"
                :disabled="first()"
                small
                text
                @click="timeTravel('back')"
              >
                <v-icon v-text="'$back'" />
                {{ labels.previous }}
              </v-btn>
              <v-spacer />
              <v-btn
                color="secondary"
                :disabled="last()"
                small
                text
                @click="timeTravel('forth')"
              >
                {{ labels.next }}
                <v-icon v-text="'$forth'" />
              </v-btn>
            </v-row>
        </v-container>
      </div>
    </div>
    <v-col v-if="expanded" cols="12" class="d-flex flex-column">
      <v-btn
        class="flex-grow-1 my-3"
        color="primary"
        @click="addLayer"
        rounded small
        :disabled="layerBtnDisabled || loading"
      >
        <v-icon class="mr-1" v-text="'$addLayer'" />
        {{ labels.add }}
      </v-btn>
      <v-btn
        class="flex-grow-1"
        color="primary"
        @click="clearLayers"
        rounded small
        :disabled="layerBtnDisabled || loading"
        v-show="showClear"
      >
        <v-icon class="mr-1" v-text="'$removeLayer'" />
        {{ labels.clear }}
      </v-btn>
    </v-col>
    <v-row v-if="!expanded">
      <v-btn
        class="flex-grow-1"
        color="primary"
        rounded small
        @click="$emit('toggleExpand', true)"
      >
        <v-icon class="mr-1" v-text="'$addLayer'" />
        {{ labels.add }}
      </v-btn>
    </v-row>
    <v-row v-if="digitanimalEnabled">
      <v-switch
        v-model="showDigitanimal"
        @change="$emit('toggleDigitanimal', showDigitanimal)"
      >
        <template v-slot:label>
          {{ labels.showDigitanimal }}
          <v-img src="@/assets/digitanimal-marker.png" max-width="16" contain class="ml-2"/>
        </template>
      </v-switch>
    </v-row>
  </v-container>
</template>

<script>
/* eslint no-param-reassign: ["error", { "props": false }] */
import { mapActions, mapState } from 'vuex';
import { errorMessages, labels } from '@/assets/texts.json';
import {
  getClosestDateInArray,
  isArrEmpty,
  lastDay,
} from '@/helpers';
import unionBy from 'lodash/unionBy';
import moment from 'moment';
import wmsService from '@/services/wms.service';
import digitanimalService from '@/services/digitanimal.service';

const Calendar = () => import('@/components/Calendar.vue');
const WeekPicker = () => import('@/components/WeekPicker.vue');

export default {
  name: 'MapButtons',
  model: {
    prop: 'mapLayers',
    event: 'setLayers',
  },
  components: {
    Calendar,
    WeekPicker,
  },
  props: {
    mapLayers: {
      type: Array[Object],
      default: () => [],
    },
    expanded: {
      type: Boolean,
      default: true,
    },
    // lista de productos para una determinada parcela
    products: {
      type: Array,
      default: () => [],
    },
  },
  inheritAttrs: false,
  data() {
    return {
      currentProduct: null,
      productItems: [],
      date: this.today(),
      datePickerReference: -1,
      errorMessages,
      inhibitUpdateWatcher: false,
      labels,
      layers: [],
      mapTypes: [
        { text: labels.daily, value: 'daily' },
        { text: labels.yearly, value: 'yearly' },
      ],
      mapType: 'daily',
      yearlyProduct: null,
      yearlySubplot: null,
      showDigitanimal: digitanimalService.userEnabled,
      digitanimalEnabled: false,
      loadingProductValues: false,
      period: null,
    };
  },
  mounted() {
    this.productItems = [];
    let lastHeader = null;
    this.products.forEach((product) => {
      if (product.product.category && product.product.category !== lastHeader) {
        this.productItems.push({
          header: product.product.category,
        });
        lastHeader = product.product.category;
      }
      if (product.type === 'daily') {
        product.date = product.product.latest_daily || this.today();
      }
      console.log(product);
      this.productItems.push(product);
    });
    digitanimalService.getConfiguration()
      .then((data) => {
        this.digitanimalEnabled = data.digitanimal.user && data.digitanimal.hasPassword;
        this.$emit('toggleDigitanimal', this.showDigitanimal);
      });
  },
  methods: {
    ...mapActions({
      setPickerDate: 'shared/setPickerDate',
      showError: 'shared/errorMsg',
      showInfo: 'shared/infoMsg',
      toggleLoading: 'shared/toggleLoading',
    }),
    addLayer() {
      if (this.currentProduct.type !== 'daily') {
        this.wmsLayers = unionBy(
          this.wmsLayers,
          [{
            id: `${this.currentProduct.type}-${this.currentProduct.product.id}-${this.currentPlot.Id}-${this.period.periodId}`,
            name: `${this.currentProduct.product.name} - ${this.period.text}`,
            visible: true,
            transparent: true,
            downloading: false,
            format: 'image/png',
            productId: this.currentProduct.product.id,
            wms_layer: this.period.wms_layer,
          }],
          'id',
        );
      } else {
        const ly = this.layers.filter((layer) => layer.survey_date === this.date
          && layer.product_id === this.currentProduct.product.id);
        if (!isArrEmpty(ly)) {
          this.wmsLayers = unionBy(
            this.wmsLayers,
            ly.map((l) => {
              l.name = `${this.currentProduct.product.name} (${l.survey_date})`;
              l.visible = true;
              l.transparent = true;
              l.downloading = false;
              l.format = 'image/png';
              return l;
            }),
            'id',
          );
        }
      }
      this.$emit('toggleExpand', false);
    },
    allowedDates(date) {
      return isArrEmpty(this.layers)
        ? false
        : this.surveyDates.includes(date);
    },
    clearLayers() {
      this.wmsLayers = [];
    },
    timeTravel(direction) {
      console.log(
        `Looking for layers ${direction === 'forth' ? 'after' : 'before'} ${this.date}`,
      );
      this.inhibitUpdateWatcher = true;
      this.updatePickerDate(this.pickerDate, direction);
    },
    async updatePickerDate(MonthYear, direction) {
      console.log(`updatePickerDate ${MonthYear}`);
      const dateRegex = /^(19|20)\d\d-(0[1-9]|1[012])$/;
      const currentDateIndex = this.surveyDates.indexOf(this.date);
      if (!dateRegex.test(MonthYear)) return;

      // test current month
      if (direction === 'back' && currentDateIndex > 0) {
        this.date = this.surveyDates[currentDateIndex - 1];
        this.addLayer();
        return;
      }
      if (direction === 'forth' && currentDateIndex < this.surveyDates.length - 1) {
        this.date = this.surveyDates[currentDateIndex + 1];
        this.addLayer();
        return;
      }

      const getLayers = direction === undefined
        ? wmsService.getLayers(
          this.currentPlot.id,
          this.currentProduct.product.id,
          `${MonthYear}-01`,
          `${MonthYear}-${lastDay(MonthYear)}`,
        )
        : wmsService.getRelativeLayers(
          this.currentPlot.id,
          this.currentProduct.product.id,
          direction === 'forth' ? 'after' : 'before',
          parseInt(MonthYear.substr(0, 4), 10), // year
          parseInt(MonthYear.substr(5, 2), 10), // month
        );
      this.toggleLoading(true);
      await getLayers.then((data) => {
        console.log('gotlayers?');
        console.log(data.layers);
        if (isArrEmpty(data.layers) && direction) {
          if (direction === 'forth') {
            this.currentProduct.lastDate = this.date;
            this.showInfo(this.errorMessages.noLaterDates);
          } else {
            this.currentProduct.firstDate = this.date;
            this.showInfo(this.errorMessages.noPreviousDates);
          }
          return;
        }
        this.layers = unionBy(this.layers, data.layers, 'id');
        if (direction) {
          this.setPickerDate(
            `${data.year}-${data.month.toString().padStart(2, '0')}`,
          );
          this.date = getClosestDateInArray(
            data.layers,
            'survey_date',
            this.date,
          );
          this.addLayer();
        }
      })
        .catch((error) => {
          console.log(error);
          this.showError(this.errorMessages.unexpected);
        })
        .finally(() => {
          this.toggleLoading(false);
        });
    },
    // busca la fecha más próxima a la seleccionada al cambiar de producto
    async updateProductDate(newProduct, oldProduct) {
      if (oldProduct === undefined) {
        this.date = newProduct.date;
      } else {
        this.toggleLoading(true);
        try {
          const data = await wmsService.getLayers(
            this.currentPlot.id,
            newProduct.product.id,
            `${this.pickerDate}-01`,
            `${this.pickerDate}-${lastDay(this.pickerDate)}`,
          );
          if (!isArrEmpty(data.layers)) {
            this.layers = unionBy(this.layers, data.layers, 'id');
          }
          const currentProductLayers = this.layers.filter((layer) => (
            layer.product_id === this.currentProduct.product.id
          ));
          if (!isArrEmpty(currentProductLayers)) {
            this.date = getClosestDateInArray(
              currentProductLayers,
              'survey_date',
              this.date,
            );
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.toggleLoading(false);
        }
      }
    },
    first() {
      return this.date
        ? this.currentProduct?.firstDate === this.date
        : true;
    },
    last() {
      return this.date
        ? this.currentProduct?.lastDate === this.date
        : true;
    },
    today() {
      return new Date().toISOString().substr(0, 10);
    },
    changeYearlyProduct() {
      if (!this.yearlyProduct || !this.yearlySubplot) {
        return;
      }
      for (let i = 0; i < this.yearlyProduct.subplots.length; i += 1) {
        const sp = this.yearlyProduct.subplots[i];
        if (this.yearlySubplot.crop === sp.crop && this.yearlySubplot.year === sp.year) {
          this.yearlySubplot = sp;
          return;
        }
      }
      this.yearlySubplot = null;
    },
  },
  computed: {
    ...mapState('shared', [
      'currentPlot',
      'loading',
      'pickerDate',
    ]),
    maxWidth() {
      return this.mobile ? '95vw' : '50vw';
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    showClear() {
      return !isArrEmpty(this.wmsLayers);
    },
    surveyDates() {
      return this.layers
        .filter((layer) => layer.product_id === this.currentProduct.product.id)
        .map((layer) => layer.survey_date).sort();
    },
    wmsLayers: {
      get() {
        return this.mapLayers;
      },
      set(layers) {
        this.$emit('setLayers', layers);
      },
    },
    layerBtnDisabled() {
      return !this.currentProduct
        || (this.currentProduct.type === 'daily'
          ? !this.date || isArrEmpty(this.surveyDates)
          : !this.period);
    },
  },
  watch: {
    currentProduct: function currentProduct(newProduct, oldProduct) {
      console.log('changing prod');
      if (newProduct.type === 'daily') {
        if (!this.currentProduct.date) return;
        if (!this.date) this.date = newProduct.date;
        const dateId = this.currentProduct.date.substr(0, 7);
        this.datePickerReference = this.currentProduct.product.id;
        this.setPickerDate(dateId);
        if (oldProduct) {
          this.updatePickerDate(dateId);
        }
        this.updateProductDate(newProduct, oldProduct);
      } else {
        this.period = null;
        if (!this.currentProduct.layers) {
          this.loadingProductValues = true;
          wmsService.getNonDailyLayers(
            this.currentPlot.id,
            this.currentProduct.product.id,
            this.currentProduct.type,
          )
            .then((layers) => {
              this.currentProduct.layers = layers.map((layer) => {
                if (layer.month) {
                  layer.text = `${layer.year}/${String(layer.month).padStart(2, '0')} ${this.labels.months[layer.month]}`;
                  layer.periodId = `${layer.year}-${String(layer.month).padStart(2, '0')}`;
                } else if (layer.week) {
                  const from = moment().year(layer.year)
                    .isoWeek(layer.week).startOf('isoWeek')
                    .subtract(1, 'days')
                    .format('D/M');
                  const to = moment().year(layer.year)
                    .isoWeek(layer.week).startOf('isoWeek')
                    .add(5, 'days')
                    .format('D/M');
                  layer.text = `${layer.year} ${labels.weekAbbr} ${layer.week} (${from}-${to})`;
                  layer.periodId = `${layer.year}-w${String(layer.week).padStart(2, '0')}`;
                } else if (!layer.week) {
                  console.log(this.currentProduct.product.show_prev_year);
                  if (this.currentProduct.product.show_prev_year) {
                    layer.text = `${layer.year - 1}-${layer.year}`;
                  } else {
                    layer.text = layer.year;
                  }
                  console.log(layer.text);
                  layer.periodId = layer.year;
                }
                return layer;
              });
            })
            .finally(() => {
              this.loadingProductValues = false;
            });
        }
      }
    },
    date() {
      this.currentProduct.date = this.date;
    },
    pickerDate() {
      if (!this.inhibitUpdateWatcher) {
        this.updatePickerDate(this.pickerDate);
      } else this.inhibitUpdateWatcher = false;
    },
    showDigitanimal(newVal) {
      digitanimalService.userEnabled = newVal;
    },
  },
};
</script>
<style>
.v-list-item__content.has-parent {
  padding-left: 2em;
}
</style>
