import debounce from 'lodash/debounce';
import { mapActions, mapState } from 'vuex';
import { errorMessages, labels } from '@/assets/texts.json';
import wmsService from '@/services/wms.service';
import { isArrEmpty } from '@/helpers';

const firstQuerySize = 500;

export default {
  metaInfo() {
    return {
      titleTemplate: `%s | ${this.name}`,
    };
  },
  data() {
    return {
      backRoute: { name: 'home' },
      errorMessages,
      filter: null,
      labels,
      plotNumber: null,
    };
  },
  mounted() {
    this.setBackRoute(this.backRoute);
    this.setSectionTitle([labels.title, this.name]);
    // Obtiene el conjunto de parcelas del usuario
    if (isArrEmpty(this.plots)) {
      this.getPlots(0, firstQuerySize);
    }
  },
  computed: {
    ...mapState('shared', {
      currentPage: 'currentPage',
      currentPlot: 'currentPlot',
      loading: 'loading',
      plotsPerPage: 'plotsPerPage',
      storedPlots: 'plots',
    }),
    plots: {
      get() {
        return this.storedPlots;
      },
      set(plots) {
        this.setPlots(plots);
      },
    },
  },
  methods: {
    ...mapActions({
      setBackRoute: 'shared/setBackRoute',
      setPlots: 'shared/setPlots',
      setSectionTitle: 'shared/setSectionTitle',
      showError: 'shared/errorMsg',
      toggleLoading: 'shared/toggleLoading',
    }),
    getPlots(page, plotsPerPage, filter) {
      this.toggleLoading(true);
      wmsService.getPlots(
        page || this.currentPage,
        plotsPerPage || this.plotsPerPage,
        filter || this.filter,
      ).then((result) => {
        this.plots = result.plots;
        this.plotNumber = result.total;
      }).catch((error) => {
        console.log(error);
        this.showError(this.errorMessages.unexpected);
      }).finally(() => {
        this.toggleLoading(false);
      });
    },
    getFilteredPlots: debounce(function getFilteredPlots() {
      this.getPlots();
    }, 1000), // timeout (ms)
  },
  watch: {
    currentPage() {
      this.getPlots();
    },
    filter() {
      this.getFilteredPlots();
    },
  },
};
