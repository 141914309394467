/* eslint no-underscore-dangle: "off" */

class CacheStore {
  constructor(localStoragePrefix = 'axiosCacheAdapter') {
    this.localStoragePrefix = localStoragePrefix;
  }

  getItem = (key) => this._getStore()[key];

  setItem = (key, value) => {
    const obj = {
      ...this._getStore(),
      [key]: value,
    };

    localStorage.setItem(this.localStoragePrefix, JSON.stringify(obj));
    return value;
  }

  removeItem = (key) => this.setItem(key, undefined);

  clear = () => {
    console.log(`Cleaning up cache: ${this.localStoragePrefix}`);
    localStorage.setItem(this.localStoragePrefix, JSON.stringify({}));
  }

  length = () => this._getStore().length;

  iterate = (fn) => this._mapObject(this._getStore(), fn);

  _getStore = () => {
    const value = JSON.parse(localStorage.getItem(this.localStoragePrefix));
    return this._isObject(value) ? value : {};
  }

  _mapObject(value, fn) {
    if (!this._isObject(value)) {
      return [];
    }

    return Object.keys(value).map((key) => fn(value[key], key));
  }

  _isObject = (value) => {
    const type = typeof value;
    return value != null && (type === 'object' || type === 'function');
  }
}

export default CacheStore;
