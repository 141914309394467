import unionBy from 'lodash/unionBy';

export const DEFAULT_PLOTS_PER_PAGE = 8;
export const DEFAULT_SUBPLOTS_PER_PAGE = 10;

const INITIAL_STATE = {
  backRoute: null,
  currentPage: 1,
  currentPlot: null,
  loading: false,
  message: null,
  messageColor: null,
  messageShow: false,
  pickerDate: null,
  plots: [],
  plotsPerPage: DEFAULT_PLOTS_PER_PAGE,
  ready: false,
  sectionTitle: null,
};

const shared = {
  namespaced: true,
  state: { ...INITIAL_STATE },
  actions: {
    cleanup({ commit }) {
      commit('cleanup');
    },
    clearMessage({ commit }) {
      commit('clearMessage');
    },
    displayMsg({ commit }, message) {
      commit(
        'displayMessage',
        { message, color: 'success' },
      );
    },
    errorMsg({ commit }, message) {
      commit(
        'displayMessage',
        { message, color: 'error' },
      );
    },
    infoMsg({ commit }, message) {
      commit(
        'displayMessage',
        { message, color: 'info' },
      );
    },
    resetSelector({ commit }) {
      commit('resetSelector');
    },
    setBackRoute({ commit }, route) {
      commit('setBackRoute', route);
    },
    setCurrentPlot({ commit }, plot) {
      commit('setCurrentPlot', plot);
    },
    setPage({ commit }, page) {
      commit('setPage', page);
    },
    setPickerDate({ commit }, date) {
      commit('setPickerDate', date);
    },
    setPlots({ commit }, plots) {
      commit('setPlots', plots);
    },
    setSectionTitle({ commit }, title) {
      commit('setSectionTitle', title);
    },
    toggleLoading({ commit }, loading) {
      commit('toggleLoading', loading);
    },
    toggleReady({ commit }, ready) {
      commit('toggleReady', ready);
    },
  },
  mutations: {
    cleanup(state) {
      console.log('cleaning up shared store');
      Object.assign(state, INITIAL_STATE);
    },
    clearMessage(state) {
      state.messageShow = false;
    },
    displayMessage(state, { message, color }) {
      state.messageShow = true;
      state.message = message;
      state.messageColor = color;
    },
    resetSelector(state) {
      state.currentPage = INITIAL_STATE.currentPage;
      state.currentPlot = INITIAL_STATE.currentPlot;
      state.plotsPerPage = INITIAL_STATE.plotsPerPage;
    },
    setBackRoute(state, route) {
      state.backRoute = route;
    },
    setCurrentPlot(state, plot) {
      state.currentPlot = plot;
    },
    setPage(state, page) {
      state.currentPage = page;
    },
    setPickerDate(state, date) {
      state.pickerDate = date;
    },
    setPlots(state, plots) {
      state.plots = unionBy(state.plots, plots, 'id');
    },
    setSectionTitle(state, title) {
      state.sectionTitle = title;
    },
    toggleLoading(state, loading) {
      if (loading === undefined) {
        state.loading = !state.loading;
      } else {
        state.loading = loading;
      }
    },
    toggleReady(state, ready) {
      if (ready === undefined) {
        state.ready = !state.ready;
      } else {
        state.ready = ready;
      }
    },
  },
};

export default shared;
