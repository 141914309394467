<template>
  <v-container>
    <v-select
      v-model="currentProductType"
      :items="productTypes"
      :label="labels.selectProductType"
    />

    <v-form
      v-model="valid"
      ref="statButtonsForm"
      v-if="currentProductType"
    >
      
      <v-autocomplete
        color="primary"
        v-model="currentProducts"
        deletable-chips
        multiple
        :items="products[currentProductType]"
        item-text="name"
        :label="labels.variables"
        return-object
        :rules="[(v) => validate(v) || labels.variableRequired ]"
        small-chips
      />
      
      <v-autocomplete
        v-model="currentYears"
        multiple
        :items="productTypeYears"
        :label="labels.years"
        small-chips
      />
      
      <v-btn
        color="primary"
        @click="setCharts"
        :disabled="!canSetCharts"
        rounded
        small
        class="mt-3"
      >
        <v-icon class="mr-2" v-text="'$addChart'" />
        {{ labels.getData }}
      </v-btn>

    </v-form>
  </v-container>
</template>

<script>
/* eslint no-param-reassign: ["error", { "props": false }] */
import { mapActions, mapState } from 'vuex';
import { labels, errorMessages } from '@/assets/texts.json';
import { isArrEmpty } from '@/helpers';
import statsService from '@/services/stats.service';

export default {
  name: 'StatButtons',
  data() {
    const productTypes = statsService.getProductTypes().map((t) => ({
      value: t,
      text: labels.productTypes[t],
      products: [],
    }));
    return {
      productTypes,
      currentProductType: null,
      plots: null,
      products: null,
      currentProducts: null,
      currentYears: [],
      years: [],
      labels,
      valid: false,
    };
  },
  methods: {
    ...mapActions({
      toggleLoading: 'shared/toggleLoading',
      toggleReady: 'shared/toggleReady',
    }),
    validate(Arr) {
      return !isArrEmpty(Arr);
    },
    setCharts() {
      this.$emit(
        'setCharts',
        {
          productType: this.currentProductType,
          years: this.currentYears,
          products: this.currentProducts,
        },
      );
    },
  },
  computed: {
    ...mapState('shared', [
      'currentPlot',
    ]),
    canSetCharts() {
      return this.valid
        && this.currentProductType
        && this.currentProducts && this.currentProducts.length
        && this.currentYears && this.currentYears.length;
    },
    productTypeYears() {
      const years = this.years[this.currentProductType];
      if (this.currentProductType === 'cambio-cobertura-suelo') {
        return years.map((y) => ({
          value: y,
          text: `${y - 1} - ${y}`,
        }));
      }
      return years;
    },
  },
  mounted() {
    this.toggleLoading(true);
    this.toggleReady(false);
    
    Promise.all([statsService.getProducts(), statsService.getYears(this.currentPlot.id)])
      .then(([products, years]) => {
        this.products = products;
        this.years = years;
      })
      .catch((error) => {
        console.log(error);
        this.$emit('error', errorMessages.unexpected);
      })
      .finally(() => { this.toggleLoading(false); });
  },
  watch: {
    currentProductType(v) {
      if (this.$refs.statButtonsForm) {
        this.$emit('productTypeChange', v);
        this.$refs.statButtonsForm.reset();
      }
      if (this.products[v]) {
        this.currentProducts = [...this.products[v]];
      }
    },
  },
};
</script>

<style>
.v-select{
  margin-right: 1vw;
}
.theme--light.v-chip, .mdi-close-circle::before{
  background: var(--v-primary-base) !important;
  color: white;
}
.v-chip{
  max-width: 10vw;
}
</style>
