<template>
  <v-container>
    <v-overlay
      absolute
      :dark="false"
      v-model="showRestore"
    >
      <v-card color="background">
        <v-card-title class="headline">
          {{ labels.restoreRequest }}
        </v-card-title>

        <v-card-text>
          <v-text-field
            :label="labels.email"
            :prepend-icon="'$email'"
            :rules="[rules.emailRequired, rules.emailFormat]"
            v-model="email"
            @keydown.enter="restoreRequest"
          />
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            color="secondary"
            text
            @click="close"
          >
            <v-icon v-text="'$close'" />
            {{ labels.close }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="restoreRequest"
          >
            <v-icon v-text="'$send'" />
            {{ labels.send }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>

    <v-overlay
      absolute
      :dark="false"
      v-model="confirmDialog"
    >
      <v-card color="background">
        <v-card-title class="headline">
          {{ labels.restoreCredentials }}
        </v-card-title>

        <v-card-text>
          <v-text-field
            :label="labels.validationCode"
            :prepend-icon="'$code'"
            :rules="[rules.codeFormat]"
            type="number"
            v-model="validationCode"
          />
          <v-text-field
            :label="labels.password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? '$visible' : '$invisible'"
            :prepend-icon="'$lock'"
            :rules="[rules.passwordRequired]"
            v-model="password"
            @click:append="showPassword = !showPassword"
          />
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            color="secondary"
            text
            @click="confirmDialog = false"
          >
            <v-icon v-text="'$close'" />
            {{ labels.close }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="restoreConfirm"
          >
            <v-icon v-text="'$send'" />
            {{ labels.send }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>
  </v-container>
</template>

<script>
import AuthService from '@/services/auth.service';
import { mapActions } from 'vuex';
import { labels, errorMessages } from '@/assets/texts.json';

export default {
  name: 'ResetForm',
  model: {
    prop: 'showRestore',
    event: 'toggleRestore',
  },
  props: {
    showRestore: {
      type: Boolean,
      defaut: false,
    },
  },
  data() {
    return {
      confirmDialog: false,
      email: null,
      labels,
      errorMessages,
      password: null,
      rules: {
        codeFormat: (v) => /^\d{6}$/.test(v) || errorMessages.badCode,
        emailFormat: (v) => /.+@.+/.test(v) || errorMessages.badEmail,
        emailRequired: (v) => !!v || labels.enterEmail,
        passwordRequired: (v) => !!v || labels.enterPassword,
      },
      showPassword: false,
      validationCode: null,
    };
  },
  methods: {
    ...mapActions({
      showError: 'shared/errorMsg',
      showMessage: 'shared/displayMsg',
      toggleLoading: 'shared/toggleLoading',
    }),
    restoreRequest() {
      this.toggleLoading(true);
      AuthService.restoreRequest(this.email)
        .then(() => {
          this.confirmDialog = true;
        }).catch((error) => {
          console.log(error.response);
          const errorInfo = error?.response?.data?.error || '';
          this.showError(
            `${this.errorMessages.restoreErr}. ${errorInfo}`,
          );
          this.close();
        }).finally(() => {
          this.toggleLoading(false);
        });
    },
    restoreConfirm() {
      this.toggleLoading(true);
      AuthService.restoreConfirm(
        this.email,
        this.validationCode,
        this.password,
      ).then(() => {
        this.showMessage(this.errorMessages.restoreOK);
        this.close();
      }).catch((error) => {
        console.log(error);
        this.showError(
          `${this.errorMessages.restoreErr}. ${this.errorMessages.tryAgain}`,
        );
      }).finally(() => {
        this.confirmDialog = false;
        this.toggleLoading(false);
      });
    },
    close() {
      this.email = null;
      this.$emit('toggleRestore', false);
    },
  },
  watch: {
    confirmDialog() {
      this.validationCode = null;
      this.password = null;
      this.showPassword = false;
    },
  },
};
</script>

<style>
</style>
