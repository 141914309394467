<template>
  <v-overlay
    absolute
    :dark="false"
  >
    <v-card elevation="15" max-width="95vw">
      <v-toolbar color="primary" dark>
        <v-toolbar-title class="text-truncate">
          {{ labels.reference }}: {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
      <v-text-field
        v-model="label"
        color="secondary"
        outlined
        clearable
        hide-details="auto"
        :prepend-icon="'$edit'"
        :label="labels.name"
        class="pb-2"
      />
      <v-textarea
        v-model="notes"
        auto-grow
        clearable
        color="secondary"
        hide-details="auto"
        :label="labels.notes"
        outlined
        :prepend-icon="'$edit'"
      />
      </v-card-text>
      <v-card-actions class="mt-4 justify-space-around">
        <v-btn
          color="secondary"
          @click="$emit('close')"
        >
          <v-icon class="mr-2" v-text="'$close'" />
          {{ labels.close }}
        </v-btn>
        <v-btn
          color="accent"
          @click="$emit('save', label, notes)"
        >
          <v-icon class="mr-2" v-text="'$save'" />
          {{ labels.save }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>
import { labels } from '@/assets/texts.json';

export default {
  name: 'PlotEditor',
  props: {
    // datos de parcela a editar
    plot: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      labels,
      label: this.plot.label,
      notes: this.plot.notes,
      title: this.plot['ref-catastral'],
    };
  },
};
</script>

<style>
</style>
