<template>
  <div>
    <plot-selector
      v-model="filter"
      :plots="plots"
      :plotNumber="plotNumber"
      :icon="'$settings'"
      :label="labels.editor"
    >
      <template v-slot:default="slotProps">
        <plot-editor
          v-if="showEdit"
          :max-width="slotProps.maxWidth"
          :plot="currentPlot"
          @close="close"
          @save="save"
        />
      </template>
    </plot-selector>
    <digitanimal-settings>

    </digitanimal-settings>
  </div>
</template>

<script>
import wmsService from '@/services/wms.service';
import SelectorMixin from '@/mixins/SelectorMixin';

const PlotEditor = () => import('@/components/PlotEditor.vue');
const PlotSelector = () => import('@/components/PlotSelector.vue');
const DigitanimalSettings = () => import('@/components/DigitanimalSettings.vue');

export default {
  name: 'EditView',
  mixins: [SelectorMixin],
  components: {
    PlotEditor,
    PlotSelector,
    DigitanimalSettings,
  },
  data() {
    return {
      showEdit: false,
    };
  },
  computed: {
    name() {
      return this.labels.settings;
    },
  },
  methods: {
    close() {
      this.showEdit = false;
    },
    save(label, notes) {
      this.toggleLoading(true);
      wmsService.updatePlot(this.currentPlot.id, label, notes)
        .then(() => {
          this.currentPlot.label = label;
          this.currentPlot.notes = notes;
        })
        .catch(() => {
          this.showError(this.errorMessages.updateError);
        })
        .finally(() => {
          this.close();
          this.toggleLoading(false);
        });
    },
  },
  watch: {
    currentPlot() {
      if (this.currentPlot) {
        this.showEdit = true;
      }
    },
  },
};
</script>

<style />
