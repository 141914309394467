<script>
/* eslint no-underscore-dangle: ["error", { "allow": ["_chart"] }] */
import { labels } from '@/assets/texts.json';
import { Bar, mixins } from 'vue-chartjs';
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
import {
  Classic20 as ColorScheme,
} from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau';
import moment from 'moment';

const { reactiveData } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveData],
  props: {
    chart: {
      type: Array[Object],
      default: () => [],
    },
    chartType: {
      type: String,
      default: '',
    },
    dateRange: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          colorschemes: {
            scheme: ColorScheme,
            reverse: process.env.NODE_ENV !== 'production',
          },
        },
        title: {
          display: true,
          text: `${this.chart.product.name} (${labels.monthly})`,
        },
        scales: {
          xAxes: [
            {
              offset: true,
              type: 'time',
              ticks: {
                min: '1970-01-01',
                max: '1970-12-31',
              },
              time: {
                displayFormats: {
                  month: 'MMM',
                },
                unit: 'month',
                tooltipFormat: 'MMM YYYY',
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: !!this.chart.product.units,
                labelString: this.chart.product.units || '',
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            title(items, data) {
              if (items && items.length) {
                const item = items[0];
                const dataset = data.datasets[items[0].datasetIndex];
                const { t } = dataset.data[item.index];
                const date = moment(t);
                return date.year(dataset.year).format('MMM YYYY');
              }
              return null;
            },
          },
        },
      },
    };
  },
  mounted() {
    this.updateChartData();
  },
  created() {
    moment.locale('es-ES');
  },
  methods: {
    updateChartData() {
      this.chartData = {
        datasets: this.chart.series.map((series) => ({
          label: series.acc ? `${series.year} (${labels.accumulated})` : series.year,
          fill: false,
          data: series.data,
          lineTension: 0,
          borderWidth: 1.5,
          year: series.year,
          type: series.acc ? 'line' : 'bar',
        })).flat(),
      };
    },
  },
  watch: {
    chart() {
      this.updateChartData();
    },
    dateRange() {
      this.updateTicks(true);
    },
  },
};

</script>

<style>
.trend-chart {
  position: relative;
}
</style>
