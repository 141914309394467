/* eslint import/no-extraneous-dependencies: ["error", {"devDependencies": true}] */
import Vue from 'vue';
import Meta from 'vue-meta';
import 'leaflet/dist/leaflet.css';

import AuthService from '@/services/auth.service';
import store from '@/store';
import vuetify from './plugins/vuetify';

import App from './App.vue';
import router from './router';
import '@mdi/font/css/materialdesignicons.css';

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV !== 'production';

Vue.use(Meta);


AuthService.getInitialState().finally(() => {
  const app = new Vue({
    el: '#app',
    router,
    store,
    vuetify,
    render: (h) => h(App),
  });

  if (window.Cypress) {
    // Add `store` to the window object only when testing with Cypress
    window.app = app;
    window.store = store;
  }
});
